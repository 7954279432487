// import { Processor } from "models/enums/processor"
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CardDto } from 'models/card';

import type { RootState } from 'store';
export type CardsState = CardDto[];

interface UpdateCardPayload {
  cardIndex: number | null;
  card: CardDto;
}
const slice = createSlice({
  name: 'cards',
  initialState: [] as CardsState,
  reducers: {
    setCards: (state, { payload: cards }: PayloadAction<CardDto[]>) => {
      return [...state, ...cards];
    },

    updateCard: (
      state,
      { payload: { cardIndex, card } }: PayloadAction<UpdateCardPayload>,
    ) => {
      state[cardIndex || 0] = card;
    },
  },
});

export const { setCards, updateCard } = slice.actions;
export default slice.reducer;
export const fetchCards = (state: RootState): CardDto[] | null => {
  return state.cards;
};
// export const fetchGpsCard = (state: RootState): CardDto => {
//   return state.cards.find((card: CardDto) => card.processor === Processor.Gps)
// }
