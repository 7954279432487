import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { StatementDto } from 'models/statement';
import prepareHeaders from 'network/util/prepare-headers';

import { STATEMENT_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export interface GetCardResquest {
  id: number;
}

export const statement = createApi({
  reducerPath: 'statementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: STATEMENT_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getStatement: builder.mutation<ApiResponse<StatementDto>, GetCardResquest>({
      query: ({ id }) => ({
        url: `detail/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetStatementMutation, reducer, middleware } = statement;
