import { Reducer, AnyAction, combineReducers } from 'redux';

import { reducer as authApiReducer } from 'network/auth';
import { reducer as authenticationApiReducer } from 'network/authentication';
import { reducer as budgetsApiReducer } from 'network/budgets';
import { reducer as cardApiReducer } from 'network/card';
import { reducer as entityApiReducer } from 'network/entity';
import { reducer as gpsTransactionApiReducer } from 'network/gpsTransaction';
import { reducer as gpsTransactionsApiReducer } from 'network/gpsTransactions';
import { reducer as limitsApiReducer } from 'network/limits';
import { reducer as notificationApiReducer } from 'network/notification';
import { reducer as notificationsApiReducer } from 'network/notifications';
import { reducer as oauthApiReducer } from 'network/oauth';
import { reducer as paymentsApiReducer } from 'network/payments';
import { reducer as personApiReducer } from 'network/person';
import { reducer as receiptApiReducer } from 'network/receipt';
import { reducer as receiptMemoApiReducer } from 'network/receiptMemo';
import { reducer as receiptTagsApiReducer } from 'network/receiptTag';
import { reducer as receiptsApiReducer } from 'network/receipts';
import { reducer as signUpApiReducer } from 'network/signUp';
import { reducer as spendControlsApiReducer } from 'network/spendControls';
import { reducer as statementApiReducer } from 'network/statement';
import { reducer as statementsApiReducer } from 'network/statements';
import { reducer as tagsApiReducer } from 'network/tags';
import { reducer as transactionsApiReducer } from 'network/transactions';
import { reducer as twoFAAPIReducer } from 'network/twoFA';
import { reducer as verificationApiReducer } from 'network/verification';
import { reducer as xeroBankFeedApiReducer } from 'network/xeroBankFeed';
import { reducer as xeroConnectionApiReducer } from 'network/xeroConnection';
import authSlice from 'store/slices/auth';
import cardsSlice from 'store/slices/cards';
import currentCardSlice from 'store/slices/currentCard';
import currentCardIndexSlice from 'store/slices/currentCardIndex';
import entitySlice from 'store/slices/entity';
import globalToastSlice from 'store/slices/globalToast';
import gpsTransactionsSlice from 'store/slices/gpsTransactions';
import jwtTokenValidReducer from 'store/slices/jwtTokenValid';
import ledgersSlice from 'store/slices/ledgers';
import notificationSlice from 'store/slices/notification';
import openReplaySlice from 'store/slices/openReplay';
import personSlice from 'store/slices/person';
import receiptsSlice from 'store/slices/receipts';
import spendControlsSlice from 'store/slices/spendControls';
import transactionsSlice from 'store/slices/transactions';

interface RootState {
  auth: ReturnType<typeof authSlice>;
  person: ReturnType<typeof personSlice>;
  entity: ReturnType<typeof entitySlice>;
  ledgers: ReturnType<typeof ledgersSlice>;
  currentCard: ReturnType<typeof currentCardSlice>;
  currentCardIndex: ReturnType<typeof currentCardIndexSlice>;
  cards: ReturnType<typeof cardsSlice>;
  spendControls: ReturnType<typeof spendControlsSlice>;
  transactions: ReturnType<typeof transactionsSlice>;
  receipts: ReturnType<typeof receiptsSlice>;
  globalToast: ReturnType<typeof globalToastSlice>;
  gpsTransactions: ReturnType<typeof gpsTransactionsSlice>;
  notification: ReturnType<typeof notificationSlice>;
  openReplay: ReturnType<typeof openReplaySlice>;
  authenticationApi: ReturnType<typeof authenticationApiReducer>;
  authApi: ReturnType<typeof authApiReducer>;
  oauthApi: ReturnType<typeof oauthApiReducer>;
  receiptsApi: ReturnType<typeof receiptsApiReducer>;
  receiptApi: ReturnType<typeof receiptApiReducer>;
  receiptMemoApi: ReturnType<typeof receiptMemoApiReducer>;
  receiptTagsApi: ReturnType<typeof receiptTagsApiReducer>;
  signUpApi: ReturnType<typeof signUpApiReducer>;
  spendControlsApi: ReturnType<typeof spendControlsApiReducer>;
  statementApi: ReturnType<typeof statementApiReducer>;
  statementsApi: ReturnType<typeof statementsApiReducer>;
  personApi: ReturnType<typeof personApiReducer>;
  cardApi: ReturnType<typeof cardApiReducer>;
  transactionsApi: ReturnType<typeof transactionsApiReducer>;
  gpsTransactionApi: ReturnType<typeof gpsTransactionApiReducer>;
  gpsTransactionsApi: ReturnType<typeof gpsTransactionsApiReducer>;
  tagsApi: ReturnType<typeof tagsApiReducer>;
  twoFAApi: ReturnType<typeof twoFAAPIReducer>;
  verificationApi: ReturnType<typeof verificationApiReducer>;
  notificationsApi: ReturnType<typeof notificationsApiReducer>;
  notificationApi: ReturnType<typeof notificationApiReducer>;
  paymentsApi: ReturnType<typeof paymentsApiReducer>;
  limitsApi: ReturnType<typeof limitsApiReducer>;
  xeroBankFeedApi: ReturnType<typeof xeroBankFeedApiReducer>;
  xeroConnectionApi: ReturnType<typeof xeroConnectionApiReducer>;
  budgets: ReturnType<typeof budgetsApiReducer>;
  // logglyApi: ReturnType<typeof logglyApiReducer>
  entityApi: ReturnType<typeof entityApiReducer>;
  jwtTokenValid: ReturnType<typeof jwtTokenValidReducer>;
}

const rootReducer = combineReducers<RootState>({
  auth: authSlice,
  person: personSlice,
  entity: entitySlice,
  ledgers: ledgersSlice,
  currentCard: currentCardSlice,
  currentCardIndex: currentCardIndexSlice,
  cards: cardsSlice,
  spendControls: spendControlsSlice,
  transactions: transactionsSlice,
  receipts: receiptsSlice,
  globalToast: globalToastSlice,
  gpsTransactions: gpsTransactionsSlice,
  notification: notificationSlice,
  openReplay: openReplaySlice,
  authenticationApi: authenticationApiReducer,
  authApi: authApiReducer,
  oauthApi: oauthApiReducer,
  receiptApi: receiptApiReducer,
  receiptMemoApi: receiptMemoApiReducer,
  receiptsApi: receiptsApiReducer,
  receiptTagsApi: receiptTagsApiReducer,
  signUpApi: signUpApiReducer,
  spendControlsApi: spendControlsApiReducer,
  statementApi: statementApiReducer,
  statementsApi: statementsApiReducer,
  tagsApi: tagsApiReducer,
  personApi: personApiReducer,
  cardApi: cardApiReducer,
  transactionsApi: transactionsApiReducer,
  gpsTransactionApi: gpsTransactionApiReducer,
  gpsTransactionsApi: gpsTransactionsApiReducer,
  twoFAApi: twoFAAPIReducer,
  budgets: budgetsApiReducer,
  verificationApi: verificationApiReducer,
  notificationsApi: notificationsApiReducer,
  notificationApi: notificationApiReducer,
  paymentsApi: paymentsApiReducer,
  limitsApi: limitsApiReducer,
  xeroBankFeedApi: xeroBankFeedApiReducer,
  xeroConnectionApi: xeroConnectionApiReducer,
  entityApi: entityApiReducer,
  jwtTokenValid: jwtTokenValidReducer,
});

const resettableRootReducer: Reducer = (
  state: RootState,
  action: AnyAction,
) => {
  if (action.type === 'reset') {
    state = {} as RootState;
  }
  return rootReducer(state, action);
};

export type AppState = ReturnType<typeof resettableRootReducer>;

export default resettableRootReducer;
