import { stringToBase64 } from 'components/util/base64';
import {
  AUTHENTICATION_API_BASE,
  CARD_API_BASE,
  ENTITY_API_BASE,
  BUDGETS_API_BASE,
  EVENTS_API_BASE,
  MEMO_API_BASE,
  NOTIFICATION_API_BASE,
  NOTIFICATIONS_API_BASE,
  PAYMENTS_API_BASE,
  OAUTH_API_BASE,
  PERSON_API_BASE,
  SIGN_UP_API_BASE,
  RECEIPTS_API_BASE,
  RECEIPT_API_BASE,
  TRANSACTIONS_API_BASE,
  GPS_TRANSACTIONS_API_BASE,
  GPS_TRANSACTION_API_BASE,
  USERS_API_BASE,
  STATEMENT_API_BASE,
  VERIFICATION_API_BASE,
  LIMITS_API_BASE,
  STATEMENTS_API_BASE,
  TAG_API_BASE,
  TAGS_API_BASE,
  XERO_API_BASE,
} from 'network/apiNamespaces';
import {
  CreateVerificationRequest,
  ResendPinRequest,
} from 'network/verification';

export const capeWWW = 'https://www.getcape.io';

// authentication
export const gpsTransactionsAccessTokenEndpoint = `${AUTHENTICATION_API_BASE}/accesstoken`;
export const getReceiptsDownloadOTCToken = `${AUTHENTICATION_API_BASE}/accessToken`;

// budgets
export const budgetsEndpoint = `${BUDGETS_API_BASE}`;
export const spendControlsEndpoint = `${ENTITY_API_BASE}/rules`;

// card
export const cardOrderEndpoint = `${CARD_API_BASE}/order`;
export const cardReplaceEndpoint = `${CARD_API_BASE}/replace`;
export const cardDetailEndpoint = (cardId: number): string =>
  `${CARD_API_BASE}/detail/${cardId}`;
export const cardActivateEndpoint = `${CARD_API_BASE}/activate`;
export const cardPinEndpoint = `${CARD_API_BASE}/pin`;
export const cardSuspendEndpoint = (cardId: number): string =>
  `${CARD_API_BASE}/suspend/${cardId}`;

// connections
export const xeroBankFeedEndpoint = `${XERO_API_BASE}/bankfeed`;
export const xeroConnectionEndpoint = `${XERO_API_BASE}/connection`;

// entity
export const makePaymentEndpoint = `${ENTITY_API_BASE}/Payment`;
export const entityDetailEndpoint = `${ENTITY_API_BASE}/Detail`;
export const entityBankAccountEndpoint = `${ENTITY_API_BASE}/BankAccount`;
export const entityMandateEndpoint = `${ENTITY_API_BASE}/Mandate`;
export const entityUsageEndpoint = `${ENTITY_API_BASE}/usage`;
export const entityPersonsEndpoint = `${ENTITY_API_BASE}/persons`;

// events
export const eventsEndpoint = EVENTS_API_BASE;

// singular gps transaction
export const gpsGetTransaction = (transactionId: number) =>
  `${GPS_TRANSACTION_API_BASE}/details/${transactionId}`;

// gps transasctions
export const gpsTransactionsEndpoint = `${GPS_TRANSACTIONS_API_BASE}`;
export const gpsPendingTransactionsEndpoint = `${GPS_TRANSACTIONS_API_BASE}/pending`;
export const gpsTransactionsExportEndpoint = `${GPS_TRANSACTIONS_API_BASE}/export`;
export const fakeFileStream = `${GPS_TRANSACTIONS_API_BASE}/fakeStream`;

// limits
export const limitsEndpoint = `${LIMITS_API_BASE}`;

// loggly
export const logglyEndpoint = 'https://logs-01.loggly.com';

// memo
export const addReceiptNote = `${MEMO_API_BASE}`;
export const removeReceiptNote = (blobID: number): string =>
  `${MEMO_API_BASE}/${blobID}`;

// notifications
export const notificationsEndpoint = `${NOTIFICATIONS_API_BASE}`;
export const notificationsMarkReadEndpoint = `${NOTIFICATIONS_API_BASE}/markread`;
export const unreadNotiCountEndpoint = `${NOTIFICATIONS_API_BASE}/unreadCount`;

// notification
export const readNotificationEndpoint = (eventId: number) =>
  `${NOTIFICATION_API_BASE}/markread/${eventId}`;

// oauth
export const oauthEndpoint = (
  base64AuthCode: string,
  guid: string,
  provider: string,
): string => `${OAUTH_API_BASE}/${base64AuthCode}/${guid}/${provider}`;

// payment
export const PaymentsEndpoint = `${PAYMENTS_API_BASE}`;

// person
export const personCreateEndpoint = `${PERSON_API_BASE}/create`;
export const personIsUniqueEndpoint = `${PERSON_API_BASE}/isunique`;
export const personDetailEndpoint = `${PERSON_API_BASE}/detail`;
export const personCardsEndpoint = `${PERSON_API_BASE}/Cards`;
export const personSummaryEndpoint = `${PERSON_API_BASE}/summary`;
export const personUpdateAddress = `${PERSON_API_BASE}/updateAddress`;
export const personUpdate = `${PERSON_API_BASE}/update`;

// receipt
export const getReceipt = `${RECEIPT_API_BASE}`;
export const addReceipt = (transactionID: number): string =>
  `${RECEIPT_API_BASE}/${transactionID}`;
export const removeReceipt = (id: number) => `${RECEIPT_API_BASE}/${id}`;
export const getReceiptImage = (id: number) => `${RECEIPT_API_BASE}/${id}`;

// receipts
export const gpsTransactinsReciptsImageDownload = `${RECEIPTS_API_BASE}/download`;
export const gpsTransactinsReciptsCSVDownload = `${RECEIPTS_API_BASE}/export`;
export const getReceiptsEndpoint = `${RECEIPTS_API_BASE}`;

// signup
export const usersSignUpCredentialsEndpoint = `${SIGN_UP_API_BASE}/credentials`;
export const usersSignUpStartEndpoint = (guid: string): string =>
  `${SIGN_UP_API_BASE}/start/${guid}`;

// statement
export const statementEndpoint = (statementId: string): string =>
  `${STATEMENT_API_BASE}/detail/${statementId}`;
export const statementsEndpoint = STATEMENTS_API_BASE;

// tags
export const gpsTransationsGetTags = `${TAGS_API_BASE}`;
export const getTags = `${TAGS_API_BASE}`;

// tag
export const addTag = `${TAG_API_BASE}`;
export const linkTag = `${TAG_API_BASE}/link`;
export const unLinkTag = (tagID: number) => `${TAG_API_BASE}/unlink/${tagID}`;

// transasctions
export const transactionsEndpoint = `${TRANSACTIONS_API_BASE}`;
export const transactionsAccessTokenEndpoint = `${TRANSACTIONS_API_BASE}/accessToken`;
export const transactionsExportEndpoint = `${TRANSACTIONS_API_BASE}/export`;

// user
export const usersLoginEndpoint = `${USERS_API_BASE}/login`;
export const usersStartLoginEndpoint = `${USERS_API_BASE}/start`;
export const usersStartLoginEmailEndpoint = (email: string): string =>
  `${usersStartLoginEndpoint}/${stringToBase64(email)}`;
export const usersRefreshEndpoint = `${USERS_API_BASE}/refresh`;
export const userResetPasswordEndpoint = `${USERS_API_BASE}/resetpassword`;
export const userChangePasswordEndpoint = `${USERS_API_BASE}/changepassword`;
export const userSetPasswordEndpoint = `${USERS_API_BASE}/setpassword`;
export const userSet2FA = (communicationType: number) =>
  `${USERS_API_BASE}/set2fa/${communicationType}`;

// verifications
export const verificationsVerifyEndpoint = (
  email: string,
  guid: string,
  otp: string,
) => `${VERIFICATION_API_BASE}/verify/${email}/${guid}/${otp}`;

export const verificationResendPin = (props: ResendPinRequest) => {
  return `${VERIFICATION_API_BASE}/resend_pin/${props.username}/${props.guid}`;
};

export const verificationCreateEndpoint = (
  props: CreateVerificationRequest,
) => {
  return `${VERIFICATION_API_BASE}/create/${props.username}`;
};
