import { EntityFactory } from 'mocks/factories/entity';
import { EntityDto } from 'models/entity';

export const getEntityDetailsFx = (): {
  success: boolean;
  data: EntityDto;
} => ({
  success: true,
  data: EntityFactory.build(),
});
