import { createListenerMiddleware } from '@reduxjs/toolkit';

import { ResponseCode } from 'network/util/api-response';
import { setJwtTokenValidity } from 'store/slices/jwtTokenValid';

export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  predicate: action => {
    // return true when the listener should run
    return (
      action.payload &&
      action.payload.success === false &&
      action.payload.code === ResponseCode.JwtTokenExpired
    );
  },
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setJwtTokenValidity(false));
  },
});
