import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import PersonDto from 'models/person';
import { PersonIsUniqueDto } from 'models/personIsUniqueDto';
import UpdatePersonStateModel from 'models/update-person-state-model';
import prepareHeaders from 'network/util/prepare-headers';

import { PERSON_API_BASE } from './apiNamespaces';
import ApiResponse, { ResponseCode } from './util/api-response';

const retryWellBaseQuery = retry(
  fetchBaseQuery({ baseUrl: PERSON_API_BASE, prepareHeaders }),
  {
    maxRetries: 3,
  },
);

const validateStatus = (resp: Response, body: ApiResponse<unknown>): boolean =>
  (resp.status === 200 && body.success) ||
  body.code === ResponseCode.JwtTokenExpired;

export interface CreatePerson {
  personUid: string;
  first: string;
  middle: string;
  last: string;
  fullName: string;
  suffix: string;
  email: string;
  mobile: string;
  DateOfBirth: string;
  address: {
    address1: string;
    address2: string;
    postTown: string;
    postCode: string;
    state: string;
    country: string;
    char2Code: string;
  };
}

export interface PersonIsUniqueRequest {
  uid: string;
  first: string;
  last: string;
  email: string;
  mobile: string;
  middle: string;
  fullName: string;
  suffix: string;
}

export interface UpdatePersonAddress {
  id?: number;
  address1: string;
  address2?: string;
  address3?: string;
  address4?: string;
  address5?: string;
  postTown?: string;
  countyField?: string;
  postCode: string;
  char2Code: string;
}

export const personApi = createApi({
  reducerPath: 'personApi',
  baseQuery: retryWellBaseQuery,
  endpoints: builder => ({
    personCreate: builder.mutation<ApiResponse<never>, CreatePerson>({
      query: params => ({
        url: 'create',
        method: 'POST',
        body: params,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    personIsUnique: builder.mutation<
      ApiResponse<PersonIsUniqueDto>,
      PersonIsUniqueRequest
    >({
      query: params => ({
        url: 'isunique',
        method: 'POST',
        body: params,
      }),
    }),

    personAddressUpdate: builder.mutation<
      ApiResponse<never>,
      UpdatePersonAddress
    >({
      query: params => ({
        url: 'updateAddress',
        method: 'PUT',
        body: params,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    personDetailsUpdate: builder.mutation<ApiResponse<PersonDto>, PersonDto>({
      query: params => ({
        url: 'update',
        method: 'PUT',
        body: params,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    personSummary: builder.mutation<ApiResponse<PersonDto>, void>({
      query: () => ({
        url: 'summary',
        method: 'GET',
        validateStatus,
      }),
    }),

    activate: builder.mutation<ApiResponse<UpdatePersonStateModel>, number>({
      query:
        /* istanbul ignore next */
        id => ({
          url: `${id}/activate`,
          method: 'PUT',
        }),
    }),

    deactivate: builder.mutation<ApiResponse<UpdatePersonStateModel>, number>({
      query:
        /* istanbul ignore next */
        id => ({
          url: `${id}/deactivate`,
          method: 'PUT',
        }),
    }),
  }),
});

export const {
  usePersonAddressUpdateMutation,
  usePersonDetailsUpdateMutation,
  usePersonSummaryMutation,
  usePersonCreateMutation,
  usePersonIsUniqueMutation,
  useActivateMutation,
  useDeactivateMutation,
  reducer,
  middleware,
} = personApi;
