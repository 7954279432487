import { Factory } from 'fishery';

import Address from 'models/address';
import { AddressTypes } from 'models/enums/address-types';

export const AddressFactory = Factory.define<Address>(() => ({
  id: 1,
  addressX: 'WOTSO Manly 19-21 S Steyne Rd   Manly NSW 2095',
  organisation: '',
  department: '',
  address1: 'unit 4',
  address2: '120 Bower Street',
  address3: '',
  address4: '',
  address5: '',
  postTown: 'Manly',
  countyField: 'NSW',
  state: '',
  stateCode: '',
  postCode: '2095',
  countryId: 13,
  country: 'Australia',
  externalReference: '',
  phone: '',
  startDate: '2021-10-13T00:00:00',
  endDate: '',
  char2Code: 'AU',
  latitude: 0,
  longitude: 0,
  placeId: '',
  frankieReference: '',
  addressType: AddressTypes.Registered,
}));
