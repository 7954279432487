import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      clipRule="evenodd"
      d="M3.381 9.734c-.471 0-.907.392-.907.817v4.607c0 .425.436.817.907.817h.825V9.734h-.825ZM0 10.55c0-1.656 1.544-3.046 3.381-3.046h2.062c.684 0 1.237.499 1.237 1.114v8.47c0 .616-.553 1.115-1.237 1.115H3.381C1.544 18.204 0 16.814 0 15.158V10.55ZM17.32 8.62c0-.616.553-1.115 1.237-1.115h2.062c1.837 0 3.381 1.39 3.381 3.046v4.607c0 1.656-1.544 3.046-3.381 3.046h-2.062c-.683 0-1.237-.499-1.237-1.114V8.62Zm2.474 1.114v6.241h.825c.471 0 .907-.392.907-.817V10.55c0-.425-.436-.817-.907-.817h-.825Z"
    />
    <Path
      clipRule="evenodd"
      d="M2.887 8.248C2.887 3.694 6.987 0 12.04 0c5.055 0 9.155 3.694 9.155 8.248 0 .615-.554 1.114-1.237 1.114s-1.237-.499-1.237-1.114c0-3.323-2.993-6.019-6.68-6.019-3.689 0-6.681 2.696-6.681 6.019 0 .615-.554 1.114-1.237 1.114-.684 0-1.237-.499-1.237-1.114ZM8.66 20.136c0-.615.554-1.114 1.237-1.114h4.124c.683 0 1.237.499 1.237 1.114v2.75c0 .615-.554 1.114-1.237 1.114H9.897c-.683 0-1.237-.499-1.237-1.115v-2.749Zm2.474 1.115v.52h1.65v-.52h-1.65Z"
    />
    <Path
      clipRule="evenodd"
      d="M20.371 16.495c.683 0 1.237.5 1.237 1.115 0 1.16-.326 2.106-.878 2.852-.547.74-1.267 1.218-1.95 1.526a7.033 7.033 0 0 1-1.824.525 7.207 7.207 0 0 1-.841.075h-.031s-.002 0-.002-1.113v1.113h-2.061c-.684 0-1.238-.499-1.238-1.114 0-.616.554-1.115 1.238-1.115h2.068l.09-.005c.084-.005.213-.016.37-.04.321-.048.73-.143 1.125-.32.389-.175.74-.421.998-.769.252-.34.462-.844.462-1.615 0-.616.554-1.115 1.237-1.115Z"
    />
  </Svg>
);

export default SvgComponent;
