import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { StatementsListDto } from 'models/statement';
import prepareHeaders from 'network/util/prepare-headers';

import { STATEMENTS_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export const statements = createApi({
  reducerPath: 'statementsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: STATEMENTS_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getStatementList: builder.mutation<ApiResponse<StatementsListDto>, void>({
      query: () => ({
        url: '',
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetStatementListMutation, reducer, middleware } = statements;
