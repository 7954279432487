import { EntityFactory } from 'mocks/factories/entity';
import { Invite } from 'models/invite';

interface ResponseBody {
  success: boolean;
  data: Invite;
}

export const getStartBodyFx = (value: boolean): ResponseBody => ({
  success: value,
  data: {
    id: 1,
    uri: 'sample string 2',
    email: 'rick@morty.com',
    createdAt: new Date(2022, 1, 10, 9, 13, 31, 8128103).toISOString(),
    expiresAt: new Date(2022, 1, 20, 9, 13, 31).toISOString(),
    purpose: 'sample string 6',
    guid: 'd0e6a338-0cd2-4f6f-ab09-333d3f379482',
    personId: 1,
    name: 'Mr. U. Test',
    entityId: 1,
    legalName: 'The Awesomest Awesome Company pty ltd',
    tradingName: 'The Awesomest Awesome Company',
    userId: 1,
    username: 'sample string 11',
    payload: 'sample string 12',
    entityContactId: 1,
    completedAt: new Date(2022, 1, 30, 9, 13, 31).toISOString(),
    limit: 25000000,
    apr: 2700.5,
    membershipInitial: 19999.99,
    membership: 100.5,
    deliveryName: 'Mr. U Deliver',
    addressLine1: '29 Morna Point rd',
    addressLine2: 'Somestreet',
    townCity: 'Anna Bay',
    region: 'NSW',
    postalCode: '2095',
    country: 'AU',
    entity: EntityFactory.build(),
  },
});
