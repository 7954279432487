import React, { useEffect, useState } from 'react';

import {
  profile as profileRoute,
  settings as settingsRoute,
  people as peopleRoute,
  dashboardHelpCentre as helpCentreRoute,
  payments as paymentRoute,
  receipts as receiptsRoute,
  transactions as transactionsRoute,
  spendControls as spendControlRoute,
  paymentHistory as paymentHistoryRoute,
  statements as statementsRoute,
} from 'components/layouts/Authorized/routes';
import ReceiptIcon from 'components/svgs/has-receipt';
import PaymenyIcon from 'components/svgs/payment';
import PeopleIcon from 'components/svgs/people';
import ProfileIcon from 'components/svgs/profile';
import SettingsIcon from 'components/svgs/settings';
import SpendControlIcon from 'components/svgs/spendControlIcon';
import TermsAndConditionsIcon from 'components/svgs/t-and-c';
import TransactionIcon from 'components/svgs/transactions';
import { FF__SPEND_CONTROLS, FF__RECEIPT_CAPTURE } from 'config/environment';
import { Roles } from 'models/enums/roles';
import { usePerson } from 'store/hooks/person';

export interface BtnLinkprops {
  testID: string;
  path: string;
  icon?: JSX.Element;
  text: string;
  color?: string;
  isHeader?: boolean;
}
const svgSize = {
  width: '36px',
  height: '36px',
};

const spendControlLink: BtnLinkprops = {
  testID: 'dashboard-spendControls-link',
  path: spendControlRoute,
  icon: <SpendControlIcon {...svgSize} />,
  text: 'Spend Controls',
};

const helpCenterLink: BtnLinkprops = {
  testID: 'bur-help-center',
  path: helpCentreRoute,
  text: 'Help Centre',
};

export const settingLink: BtnLinkprops = {
  testID: 'bur-setting',
  path: settingsRoute,
  icon: <SettingsIcon {...svgSize} />,
  text: 'Settings',
};

export const paymentLink: BtnLinkprops = {
  testID: 'bur-payment',
  path: paymentRoute,
  icon: <PaymenyIcon {...svgSize} />,
  text: 'Make a Payment',
};

export const profileLink: BtnLinkprops = {
  testID: 'bur-profile',
  path: profileRoute,
  icon: <ProfileIcon {...svgSize} />,
  text: 'Profile',
};

export const peopleLink: BtnLinkprops = {
  testID: 'bur-people',
  path: peopleRoute,
  icon: <PeopleIcon {...svgSize} />,
  text: 'People',
};

const expensesHeader: BtnLinkprops = {
  testID: 'expenses-header',
  text: 'Expenses',
  isHeader: true,
  path: '',
};

const companyHeader: BtnLinkprops = {
  testID: 'company-header',
  text: 'Company',
  isHeader: true,
  path: '',
};

const receiptsLink: BtnLinkprops = {
  testID: 'bur-receipts-link',
  path: receiptsRoute,
  text: 'Receipts',
  icon: <ReceiptIcon fill="#5A89AC" {...svgSize} />,
};

const transactionsLink: BtnLinkprops = {
  testID: 'bur-transactions-link',
  path: transactionsRoute,
  text: 'Transactions',
  icon: <TransactionIcon {...svgSize} />,
};

const StatementsLink: BtnLinkprops = {
  testID: 'statements-link',
  path: statementsRoute,
  icon: <TermsAndConditionsIcon {...svgSize} />,
  text: 'Statements',
};

const paymentHistoryLink: BtnLinkprops = {
  testID: 'payment-history-link',
  path: paymentHistoryRoute,
  icon: <PaymenyIcon {...svgSize} />,
  text: 'Payment History',
};

interface useBurgerNav {
  navList: BtnLinkprops[];
  personNavList: BtnLinkprops[];
  expensesNavList: BtnLinkprops[];
  footerList: BtnLinkprops[];
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useBurgerNav = ({
  receiptCaptureEnabled,
}: {
  receiptCaptureEnabled: boolean;
}): useBurgerNav => {
  const { person } = usePerson();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const personHeader: BtnLinkprops = {
    testID: 'person-header',
    path: '',
    text: person?.fullName,
    isHeader: true,
  };

  // component teardown
  useEffect(() => {
    return () => {
      setMenuOpen(false);
    };
  }, []);

  const personNavList = [personHeader, settingLink, profileLink];

  let expensesNavList = [expensesHeader, transactionsLink];

  const footerList = [helpCenterLink];

  let navList: BtnLinkprops[] = [];

  const userRoles = person ? [...person?.roles] : [];

  person &&
    userRoles
      .sort((a, b) => b - a)
      .forEach(role => {
        if (role !== Roles.Administrator) {
          switch (role) {
            case Roles.Owner:
              navList = [
                companyHeader,
                paymentLink,
                paymentHistoryLink,
                StatementsLink,
                peopleLink,
              ];
              receiptCaptureEnabled &&
                FF__RECEIPT_CAPTURE &&
                expensesNavList.push(receiptsLink);
              FF__SPEND_CONTROLS && navList.push(spendControlLink);
              break;

            case Roles.Customer:
              expensesNavList = [transactionsLink];
              break;

            case Roles.Accountant:
              navList = [companyHeader, paymentHistoryLink, StatementsLink];
              receiptCaptureEnabled &&
                FF__RECEIPT_CAPTURE &&
                expensesNavList.push(receiptsLink);
              break;

            default:
              break;
          }
        }
      });

  // Remove Duplicated in nav List
  navList = navList.filter(
    (item, index, self) =>
      index === self.findIndex(selfItem => selfItem.testID === item.testID),
  );

  return {
    personNavList,
    expensesNavList,
    footerList,
    navList,
    menuOpen,
    setMenuOpen,
  };
};

export default useBurgerNav;
