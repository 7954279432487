import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BudgetsDto } from 'models/budget';
import prepareHeaders from 'network/util/prepare-headers';

import { BUDGETS_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export const budgets = createApi({
  reducerPath: 'budgets',
  baseQuery: fetchBaseQuery({
    baseUrl: BUDGETS_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getBudgets: builder.mutation<ApiResponse<BudgetsDto>, void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBudgetsMutation, reducer, middleware } = budgets;
