import { Factory } from 'fishery';

import EntityPersonDto from 'models/entityPerson';

export const EntityPersonFactory = Factory.define<EntityPersonDto>(
  ({ sequence }) => ({
    id: sequence,
    uid: `${sequence}`,
    entityId: sequence,
    code: `${sequence}`,
    personId: sequence,
    name: `${sequence}-user`,
    personType: sequence,
    isUser: true,
    userId: sequence,
    email: `${sequence}@getcape.io`,
    mobile: '0422222222',
    ledgerId: sequence,
  }),
);

export const entityPeopleList = {
  total: 2,
  items: [
    EntityPersonFactory.buildList(1, { isUser: false }),
    EntityPersonFactory.buildList(1, { isUser: true }),
  ],
};
