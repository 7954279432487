import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import LogglyTracker from 'components/providers/Loggly/tracker';
import { ErrorCodes } from 'models/enums/error-codes';
import { errorTitle as title } from 'network/util/constants';
import { show } from 'store/slices/globalToast';

import { ErrorCodeText } from './error-code-text';

const tracker = new LogglyTracker();
tracker.config({
  key: true,
  session_id: '',
  inputUrl: '',
  logglyKey: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
  sendConsoleErrors: true,
  tag: process.env.REACT_APP_LOGGLY_TAG,
  useUtfEncoding: true,
});

export const errorLogger: Middleware = storeApi => next => action => {
  const isNotTwoFA =
    action.payload?.data?.code !== ErrorCodes.TwoFactorRequested &&
    action.payload?.code !== ErrorCodes.TwoFactorRequested;
  const isNotAccountSuspended =
    action.payload?.data?.code !== ErrorCodes.AccountSuspended;

  if (
    (isRejectedWithValue(action) ||
      (action.payload && action.payload.success === false)) &&
    isNotTwoFA &&
    isNotAccountSuspended
  ) {
    if (action.payload.status >= 500) {
      tracker.track(action.payload.data);

      storeApi.dispatch(
        show({
          title: action.payload.data.message,
          description: action.payload.data.exceptionMessage,
        }),
      );
    } else if (action.payload.status >= 400) {
      tracker.track(action.payload.data);

      const description =
        ErrorCodeText.find(item => item.code === action.payload.data.code)
          ?.message ??
        action.payload.data.message ??
        'There was an issue';

      storeApi.dispatch(show({ title, description }));
    } else {
      if (action.payload?.message) {
        tracker.track(action.payload?.message);
      }

      storeApi.dispatch({
        type: 'globalToast.show',
        payload: {
          title,
          description: action.payload?.message || 'There was an issue',
        },
      });

      storeApi.dispatch(
        show({
          title,
          description: action.payload?.message || 'There was an issue',
        }),
      );
    }
  }

  return next(action);
};
