import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GpsTransactionDto } from 'models/gpsTransaction';

import type { RootState } from 'store';

export type GpsTransactionsState = {
  hasMoreTransactions: boolean;
  pendingTransactions: GpsTransactionDto[];
  gpsTransactions: GpsTransactionDto[];
  currentPage: number;
  userOfCurrentLedger: string | null;
};

export type TransactionUpdatePayload = {
  id: number;
  transaction: GpsTransactionDto;
  isPending: boolean;
};

const slice = createSlice({
  name: 'gpsTransactions',
  initialState: {
    hasMoreTransactions: true,
    pendingTransactions: [],
    gpsTransactions: [],
    currentPage: 0,
    userOfCurrentLedger: null,
  } as GpsTransactionsState,
  reducers: {
    setTransactions: (
      state,
      { payload: transactions }: PayloadAction<GpsTransactionDto[]>,
    ) => {
      state.gpsTransactions = transactions;
    },
    setPendingTransactions: (
      state,
      { payload: transactions }: PayloadAction<GpsTransactionDto[]>,
    ) => {
      state.pendingTransactions = transactions;
    },
    resetCurrentPage: state => {
      state.currentPage = 0;
    },
    setHasMoreTransactions: (
      state,
      { payload: hasMoreTransactions }: PayloadAction<boolean>,
    ) => {
      state.hasMoreTransactions = hasMoreTransactions;
    },
    incrementCurrentPage: state => {
      state.currentPage = state.currentPage + 1;
    },
    updateTransaction: (
      state,
      {
        payload: { id, transaction, isPending },
      }: PayloadAction<TransactionUpdatePayload>,
    ) => {
      if (isPending) {
        const transactionIndex = state.pendingTransactions.findIndex(
          item => item.id === id,
        );
        state.pendingTransactions[transactionIndex] = transaction;
      } else {
        const transactionIndex = state.gpsTransactions.findIndex(
          item => item.id === id,
        );
        state.gpsTransactions[transactionIndex] = transaction;
      }
    },
  },
});

export const {
  setTransactions,
  setPendingTransactions,
  resetCurrentPage,
  setHasMoreTransactions,
  updateTransaction,
  incrementCurrentPage,
} = slice.actions;
export default slice.reducer;
export const fetchTransactions = (state: RootState): GpsTransactionsState =>
  state.gpsTransactions;
