import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CommunicationType } from 'models/enums/communication-type';
import prepareHeaders from 'network/util/prepare-headers';

import { VERIFICATION_API_BASE } from './apiNamespaces';

export interface ResendPinResponse {
  success: boolean;
  code?: number;
  data: {
    guid: string;
  };
}

export interface ResendPinRequest {
  username: string;
  guid: string;
  communicationType: CommunicationType;
}

export interface CreateVerificationResponse {
  success: boolean;
  code: number;
  message: string;
  details: string[];
  errors: Error[];
  changes: unknown[];
  data: {
    personId: number;
    userId: number;
    guid: string;
    communicationType: CommunicationType;
    email: string;
    mobile: string;
    pin: string;
  };
}

export interface CreateVerificationRequest {
  username: string;
  communicationType: CommunicationType;
}

export interface VerifyVerificationResponse {
  success: boolean;
  code?: number;
  data: {
    token: string;
  };
}

export interface VerifyVerificationRequest {
  email: string;
  otpCode: string;
  guid: string;
}

export const verification = createApi({
  reducerPath: 'verificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: VERIFICATION_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    createVerification: builder.mutation<
      CreateVerificationResponse,
      CreateVerificationRequest
    >({
      query: ({ username, communicationType }) => ({
        url: `create/${username}`,
        method: 'POST',
        params: { communicationType },
      }),
    }),
    resendPin: builder.mutation<ResendPinResponse, ResendPinRequest>({
      query: ({ username, guid, communicationType }) => ({
        url: `resend_pin/${username}/${guid}`,
        method: 'POST',
        params: { communicationType },
      }),
    }),
    verifyVerification: builder.mutation<
      VerifyVerificationResponse,
      VerifyVerificationRequest
    >({
      query: ({ email, otpCode, guid }) => ({
        url: `verify/${email}/${guid}/${otpCode}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useResendPinMutation,
  useCreateVerificationMutation,
  useVerifyVerificationMutation,
  reducer,
  middleware,
} = verification;
