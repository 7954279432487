// import { ReactComponent as TransactionsIcon } from "assets/svgs/icons/transactions.group.svg";
import { Suspense, lazy } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Route, Routes, Navigate } from 'react-router-dom';

import { RouteByPersonState } from 'components/inline/RouteByPersonState';
import ScrollToTop from 'components/inline/ScrollToTop';
import DashboardLayout from 'components/layouts/Dashboard';
import LazySpinner from 'components/layouts/LazySpinner';
import OnboardingLayout from 'components/layouts/Onboarding';
import PublicLayout from 'components/layouts/Public';
import { DownloadCSV } from 'components/pages/Transactions/DownloadCSV/DownloadCSV';
import AuthenticatedProvider from 'components/providers/Authenticated';
import EventsProvider from 'components/providers/Events/signalR';
import ProcessorProvider from 'components/providers/Processor';
import SessionPersistence from 'components/providers/SessionPersistence';
import { isUserHasState } from 'components/util/isUserHasState';
import {
  FF__SPEND_CONTROLS,
  FF__BUDGETS,
  FF__RECEIPT_CAPTURE,
} from 'config/environment';
import { Processor } from 'models/enums/processor';
import { Roles } from 'models/enums/roles';
import { States } from 'models/enums/states';
import { useLedgers } from 'store/hooks/ledgers';

import Maybe from './MaybeRender';
import * as authRoutes from './routes';

/* istanbul ignore next */
const Dashboard = lazy(() => import('components/pages/Dashboard'));
const ActivateCard = lazy(() => import('components/pages/ActivateCard'));
const ActivateFlowDirectDebit = lazy(
  () => import('components/pages/ActivateFlowDirectDebit'),
);
const AddPerson = lazy(
  () => import('components/pages/People/AddPersonForm/AddPersonForm'),
);
const Budgets = lazy(() => import('components/pages/Budgeting'));
const DirectDebit = lazy(() => import('components/pages/DirectDebit'));
const TransactionsComp = lazy(
  () => import('components/pages/Transactions/RailsTransactions'),
);
const SpendControls = lazy(
  () => import('components/pages/Budgeting/SpendControls'),
);
const ChangePassword = lazy(
  () => import('components/pages/ChangePassword/ChangePassword'),
);
const Connections = lazy(
  () => import('components/pages/Connections/Connections'),
);
const ConnectionsXero = lazy(
  () => import('components/pages/Connections/ConnectionsXero'),
);
const EnableTwoFA = lazy(() => import('components/pages/EnableTwoFA'));
const FreezeCard = lazy(() => import('components/pages/FreezeCard'));
const HelpCentre = lazy(() => import('components/pages/HelpCentre/HelpCentre'));
const MyCards = lazy(() => import('components/pages/MyCards'));
const Notifications = lazy(() => import('components/pages/Notifications'));
const NotificationPage = lazy(
  () => import('components/pages/Notifications/ShowTemplate'),
);
const OrderCard = lazy(() => import('components/pages/OrderCard'));
const Payments = lazy(() => import('components/pages/Payments'));
const PaymentHistory = lazy(() => import('components/pages/PaymentHistory'));
const Users = lazy(() => import('components/pages/People/Users'));
const PinCard = lazy(() => import('components/pages/Pin'));
const Profile = lazy(() => import('components/pages/Profile/Profile'));
/* istanbul ignore next */
const ReceiptsComp = lazy(() => import('components/pages/Receipts'));
const Settings = lazy(() => import('components/pages/Settings'));
const ReplaceCard = lazy(() => import('components/pages/ReplaceCard'));
const SetupTwoFA = lazy(() => import('components/pages/SetupTwoFA'));
const Statement = lazy(() => import('components/pages/Statement'));
const Statements = lazy(() => import('components/pages/Statements'));
/* istanbul ignore next */
const GpsTransactionsComp = lazy(
  () => import('components/pages/Transactions/GpsTransactions'),
);
const UnfreezeCard = lazy(() => import('components/pages/UnfreezeCard'));

interface Props {
  GpsTransactions?: typeof GpsTransactionsComp;
  Transactions?: typeof TransactionsComp;
  Receipts?: typeof ReceiptsComp;
}

const AuthenticatedRouter = ({
  Transactions = TransactionsComp,
  GpsTransactions = GpsTransactionsComp,
  Receipts = ReceiptsComp,
}: Props): JSX.Element => {
  const {
    ledgerState: { selectedProcessor },
  } = useLedgers();

  return (
    <SessionPersistence>
      <AuthenticatedProvider>
        <EventsProvider>
          <ProcessorProvider>
            <Routes>
              <Route
                path={`${authRoutes.onboarding}/*`}
                element={
                  <OnboardingLayout>
                    <Routes>
                      <Route
                        path={authRoutes.ddSetupSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <DirectDebit person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.obHelpCentreSegment}
                        element={<HelpCentre />}
                      />
                      <Route
                        path={authRoutes.obOrderCardSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <OrderCard person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                    </Routes>
                  </OnboardingLayout>
                }
              />
              <Route
                path={`${authRoutes.dashboard}/2fa/*`}
                element={
                  <PublicLayout>
                    <Routes>
                      <Route
                        path={authRoutes.twoFAStartSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <EnableTwoFA person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.twoFAConfirmationSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <SetupTwoFA person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                    </Routes>
                  </PublicLayout>
                }
              />
              <Route
                path={`${authRoutes.dashboard}/*`}
                element={
                  <DashboardLayout>
                    <ScrollToTop />
                    <Routes>
                      <Route
                        index
                        element={
                          <Maybe>
                            {resolved => {
                              /* istanbul ignore next */
                              if (selectedProcessor === Processor.Gps) {
                                /* istanbul ignore next */
                                return (
                                  <RouteByPersonState person={resolved.person}>
                                    <Suspense fallback={<LazySpinner />}>
                                      <Dashboard />
                                    </Suspense>
                                  </RouteByPersonState>
                                );
                                /* istanbul ignore next */
                              }

                              return (
                                <RouteByPersonState person={resolved.person}>
                                  <Suspense fallback={<LazySpinner />}>
                                    <Transactions
                                      DownloadCSV={DownloadCSV}
                                      InfiniteScroller={InfiniteScroll}
                                      person={resolved.person}
                                      title="Dashboard"
                                    />
                                  </Suspense>
                                </RouteByPersonState>
                              );
                            }}
                          </Maybe>
                        }
                      />

                      <Route
                        path={authRoutes.receiptsSegment}
                        element={
                          <>
                            {FF__RECEIPT_CAPTURE && (
                              <Maybe>
                                {
                                  /* istanbul ignore next */
                                  () => (
                                    <Suspense fallback={<LazySpinner />}>
                                      <Receipts
                                        InfiniteScroller={InfiniteScroll}
                                      />
                                    </Suspense>
                                  )
                                }
                              </Maybe>
                            )}
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.transactionsSegment}
                        element={
                          <>
                            {selectedProcessor === Processor.Rails && (
                              <Maybe>
                                {resolved => {
                                  return (
                                    <Suspense fallback={<LazySpinner />}>
                                      <Transactions
                                        DownloadCSV={DownloadCSV}
                                        InfiniteScroller={InfiniteScroll}
                                        person={resolved.person}
                                      />
                                    </Suspense>
                                  );
                                }}
                              </Maybe>
                            )}
                            {selectedProcessor === Processor.Gps && (
                              <Maybe>
                                {
                                  /* istanbul ignore next */
                                  resolved => (
                                    <Suspense fallback={<LazySpinner />}>
                                      <GpsTransactions
                                        enableDownload={true}
                                        InfiniteScroller={InfiniteScroll}
                                        mode="page"
                                        person={resolved.person}
                                      />
                                    </Suspense>
                                  )
                                }
                              </Maybe>
                            )}
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.paymentsSegement}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <Payments person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />

                      <Route
                        path={authRoutes.paymentHistorySegement}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <PaymentHistory />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />

                      <Route
                        path={authRoutes.spendControlsSegment}
                        element={
                          <>
                            {FF__SPEND_CONTROLS && (
                              <Maybe
                                authorizedRoles={[
                                  Roles.Administrator,
                                  Roles.BudgetOwner,
                                  Roles.Owner,
                                  Roles.testAdminControl,
                                  Roles.testOwnerControl,
                                ]}
                              >
                                {() => (
                                  <Suspense fallback={<LazySpinner />}>
                                    <SpendControls />
                                  </Suspense>
                                )}
                              </Maybe>
                            )}
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.budgetsSegment}
                        element={
                          <>
                            {FF__BUDGETS && (
                              <Maybe
                                authorizedRoles={[
                                  Roles.Administrator,
                                  Roles.BudgetOwner,
                                  Roles.Accountant,
                                ]}
                              >
                                {resolved => (
                                  <Suspense fallback={<LazySpinner />}>
                                    <Budgets roles={resolved.person.roles} />
                                  </Suspense>
                                )}
                              </Maybe>
                            )}
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.peopleSegment}
                        element={
                          <>
                            {
                              <Maybe>
                                {() => (
                                  <Suspense fallback={<LazySpinner />}>
                                    <Users />
                                  </Suspense>
                                )}
                              </Maybe>
                            }
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.peopleAddSegment}
                        element={
                          <>
                            {' '}
                            {
                              <Maybe>
                                {resolved => (
                                  <Suspense fallback={<LazySpinner />}>
                                    <AddPerson person={resolved.person} />
                                  </Suspense>
                                )}
                              </Maybe>
                            }
                          </>
                        }
                      />
                      <Route
                        path={authRoutes.connectionsSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <Connections />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.connectionsXeroSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <ConnectionsXero />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.freezeCardSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <FreezeCard />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.unFreezeCardSegement}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <UnfreezeCard />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.pinReminderSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <PinCard />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.orderCardSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <OrderCard person={resolved.person} />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.replaceCardSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <ReplaceCard />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.myCardsSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <MyCards />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.activateCardSegment}
                        element={
                          <Maybe>
                            {resolved =>
                              isUserHasState(
                                resolved.person,
                                States.MandateSetup,
                              ) ? (
                                <Suspense fallback={<LazySpinner />}>
                                  <ActivateCard />
                                </Suspense>
                              ) : (
                                <Navigate
                                  replace
                                  to={authRoutes.activateFlowDDSetup}
                                />
                              )
                            }
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.activateFlowDDSetupSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <ActivateFlowDirectDebit />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.dashboardHelpCentreSegment}
                        element={
                          <Suspense fallback={<LazySpinner />}>
                            <HelpCentre />
                          </Suspense>
                        }
                      />
                      <Route
                        path={`${authRoutes.statementSegment(':id')}`}
                        element={
                          <Suspense fallback={<LazySpinner />}>
                            <Statement />
                          </Suspense>
                        }
                      />

                      <Route
                        path={authRoutes.statementsSegment}
                        element={
                          <Suspense fallback={<LazySpinner />}>
                            <Statements />
                          </Suspense>
                        }
                      />

                      <Route
                        path={authRoutes.changePasswordSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <ChangePassword />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.profileSegment}
                        element={
                          <Maybe>
                            {resolved => (
                              <Suspense fallback={<LazySpinner />}>
                                <Profile
                                  person={resolved.person}
                                  entity={resolved.entity}
                                />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.settingsSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <Settings />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.notificationSegment}
                        element={
                          <Maybe>
                            {() => (
                              <Suspense fallback={<LazySpinner />}>
                                <Notifications
                                  InfiniteScroller={InfiniteScroll}
                                />
                              </Suspense>
                            )}
                          </Maybe>
                        }
                      />
                      <Route
                        path={authRoutes.notificationPageSegment(':id')}
                        element={
                          <Maybe>
                            {
                              /* istanbul ignore next: could be related to signalr being removed? */
                              () => (
                                <Suspense fallback={<LazySpinner />}>
                                  <NotificationPage />
                                </Suspense>
                              )
                            }
                          </Maybe>
                        }
                      />

                      <Route
                        path="*"
                        element={<Navigate to={authRoutes.dashboard} />}
                      />
                    </Routes>
                  </DashboardLayout>
                }
              />
              <Route
                path="*"
                element={<Navigate to={authRoutes.dashboard} />}
              />
            </Routes>
          </ProcessorProvider>
        </EventsProvider>
      </AuthenticatedProvider>
    </SessionPersistence>
  );
};

export default AuthenticatedRouter;
