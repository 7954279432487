import { useEffect, useState } from 'react';
import {
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormGetValues,
  FieldError,
  Control,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  linkExpired as linkExpiredRoute,
  login as loginRoute,
} from 'components/layouts/Public/routes';
import {
  useSetCredentialsMutation as useSetPasswordMutation,
  useStartQuery,
} from 'network/signUp';

interface FormValues {
  username: string;
  password: string;
  confirmPassword: string;
}

interface SetcredentialsProps {
  guid: string;
  email: string | undefined;
  onSubmit: SubmitHandler<FormValues>;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  isLoading: boolean;
  errors: Record<string, FieldError>;
  showPage: 'confirmation' | 'setUpForm';
  onComplete: () => void;
  control: Control<FormValues>;
}

type Params = {
  guid: string;
};

export const useSetCredentials = (): SetcredentialsProps => {
  const { guid } = useParams<keyof Params>() as Params;
  const { data, isSuccess } = useStartQuery(guid);
  const navigate = useNavigate();

  const [showPage, setShowPage] = useState<'confirmation' | 'setUpForm'>(
    'setUpForm',
  );

  const [setPassword, { isLoading }] = useSetPasswordMutation();

  useEffect(() => {
    return () => {
      setShowPage('setUpForm');
    };
  }, []);

  useEffect(() => {
    if (isSuccess && data && data.success === false) {
      navigate(linkExpiredRoute);
    }
  }, [data, isSuccess, history]);

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();
    await setPassword({ password: data.password, guid })
      .unwrap()
      .then(() => {
        setShowPage('confirmation');
      });
  };

  const onComplete = () => {
    navigate(loginRoute);
  };

  return {
    guid,
    email: data?.data?.email,
    onSubmit,
    handleSubmit,
    getValues,
    isLoading,
    errors,
    showPage,
    onComplete,
    control,
  };
};

export const text = {
  header: 'Set up your Cape login',
  content:
    'Congratulations on opening your Cape account. To get started, please choose your password.',
  contentWithGoogle:
    'Congratulations on opening your Cape account. To get started you can choose to set up your own password or you can login using Single Sign on with Google.',

  contentB: 'Once you’ve registered you can login using those details.',
  instruction: '(Please use the email address you signed up with)',
  submitBtn: 'Continue',
  successHeader: 'Success!',
  successContent:
    "Thanks for setting up your Cape login details. You're now ready to login and order your first card.",
  completeBtn: 'Continue',
};
