import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EntityDto } from 'models/entity';
import EntityPersonDto from 'models/entityPerson';

import type { RootState } from 'store';

export type EntityState = EntityDto | null;

const slice = createSlice({
  name: 'entity',
  initialState: null as EntityState,
  reducers: {
    setEntity: (
      state: EntityState,
      { payload: entity }: PayloadAction<EntityDto>,
    ) => (state = entity),
    setEntityPersons: (
      state: EntityState,
      { payload: persons }: PayloadAction<EntityPersonDto[]>,
    ) => {
      state && (state.persons = persons);
    },
  },
});

export const { setEntity, setEntityPersons } = slice.actions;
export default slice.reducer;
export const selectEntity = (state: RootState): EntityDto | null => {
  return state.entity;
};

// export const selectEntityPersons = (
//   state: RootState
// ): EntityPersonDto[] | null => {
//   return state.entity?.persons
// }
