import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import BlobDto from 'models/blob';
import prepareHeaders from 'network/util/prepare-headers';

import { RECEIPT_API_BASE } from './apiNamespaces';

export interface GetReceiptResponse {
  success: boolean;
  data: string;
}

export const receipt = createApi({
  reducerPath: 'receiptApi',
  baseQuery: fetchBaseQuery({
    baseUrl: RECEIPT_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    addReceipt: builder.mutation<
      {
        success: boolean;
        data: BlobDto;
      },
      {
        transactionID: number;
        data: FormData;
      }
    >({
      query: ({ data, transactionID }) => ({
        url: `${transactionID}`,
        method: 'POST',
        body: data,
      }),
    }),
    getReceipt: builder.mutation<
      GetReceiptResponse,
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `${id}`,
        method: 'GET',
      }),
    }),
    removeReceipt: builder.mutation<
      { success: boolean },
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddReceiptMutation,
  useGetReceiptMutation,
  useRemoveReceiptMutation,
  reducer,
  middleware,
} = receipt;
