import { createContext } from 'react';

type ContextProps = {
  isLoading: boolean;
  isLoadingError: boolean;
};

const context = createContext<ContextProps>({} as ContextProps);
export default context;

export const Consumer = context.Consumer;
export const Provider = context.Provider;

// export const useCurrentCardContext = ():ContextProps => useContext(context);
