import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={19} viewBox="0 0 24 19" fill="none" {...props}>
    <Path
      clipRule="evenodd"
      d="M2.588 2.589a.311.311 0 0 0-.097.235v12.871c0 .084.035.17.098.236a.311.311 0 0 0 .235.097h18.352c.04 0 .13-.022.22-.113.09-.09.113-.18.113-.22V2.824a.345.345 0 0 0-.098-.236.311.311 0 0 0-.235-.097H2.825a.345.345 0 0 0-.236.098ZM0 2.824C0 1.18 1.348 0 2.824 0h18.352C22.82 0 24 1.348 24 2.824v12.871c0 1.519-1.305 2.824-2.823 2.824H2.823C1.18 18.519 0 17.171 0 15.696V2.823Z"
    />
    <Path
      clipRule="evenodd"
      d="M0 6.81c0-.688.558-1.246 1.246-1.246h21.508a1.246 1.246 0 1 1 0 2.491H1.246A1.246 1.246 0 0 1 0 6.81Z"
    />
    <Path d="M19.1 14.782a1.246 1.246 0 1 0 0-2.491 1.246 1.246 0 0 0 0 2.491Z" />
    <Path d="M16.941 14.782a1.246 1.246 0 1 0 0-2.491 1.246 1.246 0 0 0 0 2.491Z" />
    <Path
      clipRule="evenodd"
      d="M2.588 2.589a.311.311 0 0 0-.097.235v12.871c0 .084.035.17.098.236a.311.311 0 0 0 .235.097h18.352c.04 0 .13-.022.22-.113.09-.09.113-.18.113-.22V2.824a.345.345 0 0 0-.098-.236.311.311 0 0 0-.235-.097H2.825a.345.345 0 0 0-.236.098ZM0 2.824C0 1.18 1.348 0 2.824 0h18.352C22.82 0 24 1.348 24 2.824v12.871c0 1.519-1.305 2.824-2.823 2.824H2.823C1.18 18.519 0 17.171 0 15.696V2.823Z"
    />
    <Path
      clipRule="evenodd"
      d="M0 6.81c0-.688.558-1.246 1.246-1.246h21.508a1.246 1.246 0 1 1 0 2.491H1.246A1.246 1.246 0 0 1 0 6.81Z"
    />
    <Path d="M19.1 14.782a1.246 1.246 0 1 0 0-2.491 1.246 1.246 0 0 0 0 2.491Z" />
    <Path d="M16.941 14.782a1.246 1.246 0 1 0 0-2.491 1.246 1.246 0 0 0 0 2.491Z" />
  </Svg>
);

export default SvgComponent;
