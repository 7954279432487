export enum ErrorCodes {
  /// <summary>
  /// By default it will be zero which means that there is no error code to give
  /// </summary>
  Ok = 0,
  /// <summary>
  /// The Account number expected was not provided
  /// </summary>
  AccountNumberNull = 1,
  /// <summary>
  /// The password is null, not a good idea
  /// </summary>
  PasswordNull = 2,
  /// <summary>
  /// The password is too short, also not a good idea
  /// </summary>
  PasswordTooShort = 3,
  /// <summary>
  /// We expected a non 0 personId and if missing this is a problem
  /// </summary>
  PersonIdInvalid = 4,
  /// <summary>
  /// We expected an email field and it was empty
  /// </summary>
  EmailNull = 5,
  /// <summary>
  /// The email you gave us was invalid in some way, could be length, could be its format
  /// </summary>
  EmailInvalid = 6,
  /// <summary>
  /// We expected a username and we did not get one
  /// </summary>
  UsernameNull = 7,
  /// <summary>
  /// We expected a username but it was invalid, probably a length violation, but maybe we have some prohibited characters in there
  /// </summary>
  UsernameInvalid = 8,
  /// <summary>
  /// The salt is missing
  /// </summary>
  SaltNull = 9,
  /// <summary>
  /// The salt is too large
  /// </summary>
  SaltTooLong = 10,
  /// <summary>
  /// We ere expecting a created at date time, and it was null or perhaps the default value
  /// </summary>
  CreatedAtMissing = 11,
  /// <summary>
  /// A url supplied is too long we have max limits on urls in various places.
  /// </summary>
  UrlTooLong = 12,
  /// <summary>
  /// The amount is too small or too large.
  /// </summary>
  AmountInvalid = 13,
  /// <summary>
  /// I was expecting a Phone number (mobile or landline) but it was empty or null
  /// </summary>
  PhoneNull = 14,
  /// <summary>
  /// I was expecting a country code (iso2, or iso3) but it was empty or null
  /// </summary>
  CountryNull = 15,
  /// <summary>
  /// There were errors in processing, expect more in result.Details to say why
  /// </summary>
  ErrorsInProcessing = 16,
  /// <summary>
  /// A {{ }} token in the body of text did not have a corresponding field in the collection of objects to replace it with
  /// </summary>
  TokenReplacementFailed = 17,
  /// <summary>
  /// The userDto was null
  /// </summary>
  UserDtoNull = 18,
  /// <summary>
  /// The UserId was invalid
  /// </summary>
  UserIdInvalid = 19,
  /// <summary>
  /// The username is already present and this has to be unique
  /// </summary>
  UsernameExists = 20,
  /// <summary>
  /// The user was not found
  /// </summary>
  UserNotFound = 21,
  /// <summary>
  /// That email exists on a user an must be unique
  /// </summary>
  EmailExists = 22,
  /// <summary>
  ///  Inside a dto, or a parameter we are missing a value inside it, if this is thrown the address line 1 must be provided
  /// </summary>
  AddressLine1Null = 23,
  /// <summary>
  /// The Postcode was null
  /// </summary>
  PostcodeNull = 24,
  /// <summary>
  /// The countryId was not valid must be greater than 0
  /// </summary>
  CountryIdInvalid = 25,
  /// <summary>
  /// We were not given a repository to work with
  /// </summary>
  NoRepositoryProvided = 26,
  /// <summary>
  /// The Id of the address object is not valid
  /// </summary>
  AddressIdInvalid = 27,
  /// <summary>
  /// The Ip Address is empty
  /// </summary>
  IpAddressNull = 28,
  /// <summary>
  /// The first name is null
  /// </summary>
  FirstNameNull = 29,
  /// <summary>
  /// The last name is null
  /// </summary>
  LastNameNull = 30,
  /// <summary>
  /// The role is it invalid
  /// </summary>
  RoleIdInvalid = 31,
  /// <summary>
  /// We have a duplicate user based on name
  /// </summary>
  DuplicateUsers = 32,
  /// <summary>
  /// The useragent is null
  /// </summary>
  UserAgentNull = 33,
  /// <summary>
  /// we have failed to find the comms we were after
  /// </summary>
  CommunicationNotFound = 34,
  /// <summary>
  /// The Contact Type is missing
  /// </summary>
  ContactTypeIdInvalid = 35,
  /// <summary>
  /// The  description column in this object or param is empty and we are expecting something
  /// </summary>
  DescriptionNull = 36,
  /// <summary>
  /// We have been given a column called code and we expect there to be a code only this ones empty
  /// </summary>
  CodeNull = 37,
  /// <summary>
  /// We do not have a valid uri as its null
  /// </summary>
  UriNull = 38,
  /// <summary>
  /// We had a problem dealing with the external slack service
  /// </summary>
  ExternalSlackService = 39,
  /// <summary>
  /// The email dto was null
  /// </summary>
  EmailDtoNull = 40,
  /// <summary>
  /// The message is null
  /// </summary>
  MessageIsNull = 41,
  /// <summary>
  /// You haven't mentioned anybody to send to
  /// </summary>
  NoRecipients = 42,
  /// <summary>
  /// This is raised when we have an error with the external send grid service
  /// </summary>
  SendGridClientError = 43,
  /// <summary>
  /// we don't have a subject for the email which is a bit bad
  /// </summary>
  SubjectIsNull = 44,
  /// <summary>
  /// We could not find that entity, or a selected entity in the DB
  /// </summary>
  EntityNotFound = 45,
  /// <summary>
  /// We need an id and the minimum value is 1
  /// </summary>
  CannotBeLessThan1 = 46,
  /// <summary>
  /// The Expiring link is not found
  /// </summary>
  ExpiringLinkExpired = 47,
  /// <summary>
  /// the account is locked, this may be temporary
  /// </summary>
  IsLocked = 48,
  /// <summary>
  /// the account is suspended
  /// </summary>
  IsSuspended = 49,
  /// <summary>
  /// We do not have a login dto so that is bad
  /// </summary>
  LoginDtoNull = 50,
  /// <summary>
  /// We could not find an expiring link
  /// </summary>
  ExpiringLinkNotFound = 51,
  /// <summary>
  /// This JwtToken is expired
  /// </summary>
  JwtTokenExpired = 52,
  /// <summary>
  /// we did not have a JwtToken
  /// </summary>
  JwtTokenIsNull = 53,
  /// <summary>
  /// We were not given a good reset pasword dto
  /// </summary>
  ResetPassWordModelIsNull = 54,
  /// <summary>
  /// The Token was null
  /// </summary>
  TokenNull = 55,
  /// <summary>
  /// the username or password was invalid
  /// </summary>
  UserPassInvalid = 56,
  /// <summary>
  /// When we try to change password and we give it a crappy dto
  /// </summary>
  ChangePasswordDtoNull = 57,
  /// <summary>
  /// The password has been deemed to crappy to be allowed
  /// </summary>
  PasswordTooWeak = 58,
  /// <summary>
  /// The contact method is not correct
  /// </summary>
  ContactMethodIdInvalid = 59,
  /// <summary>
  /// The External reference is missing and we need to find it
  /// </summary>
  ExternalReferenceMissing = 60,
  /// <summary>
  /// The account was locked
  /// </summary>
  AccountLocked = 61,
  /// <summary>
  /// The Account is suspended
  /// </summary>
  AccountSuspended = 62,
  /// <summary>
  /// Passwords as supplied don't match each other
  /// </summary>
  PasswordsDontMatch = 63,
  /// <summary>
  /// There was an error in validation look at the details for more info, or better still put it in debug and find out why
  /// </summary>
  ValidationError = 64,
  /// <summary>
  /// This was two factor requested
  /// </summary>
  TwoFactorRequested = 65,
  /// <summary>
  /// The argument is null
  /// </summary>
  ArgumentNull = 66,
  /// <summary>
  /// when we are doing two factor authentication and we get the pin number wrong
  /// </summary>
  VerificationPinWrong = 67,
  /// <summary>
  /// We have no mobile
  /// </summary>
  NoMobile = 68,
  /// <summary>
  /// This is when we are given a tag and we expect it to have something and its null
  /// </summary>
  TagNull = 69,
  /// <summary>
  /// This is when we get given a duff dto, shouldn't happen but we protect against null objects
  /// </summary>
  DtoNull = 70,
  /// <summary>
  /// When we validated the object we found that something was wrong, it did not pass the validation results and is rejected
  /// </summary>
  DtoInvalid = 71,
  /// <summary>
  /// when we try to fetch (i.e. to Update, or Read) and we cant find it
  /// </summary>
  NotFound = 72,
  /// <summary>
  /// When we find one of the "things" you are trying to add
  /// </summary>
  DuplicateObject = 73,
  /// <summary>
  /// Returned by deletes because we have discovered that something references it and therefore we cant delete it
  /// </summary>
  ForeignKey = 74,
  /// <summary>
  /// We were given an Id and we know it can't possibly return an object so we reject it, this is to prevent trips to the DB when we have a bad Id
  /// </summary>
  IdInvalid = 75,
  /// <summary>
  /// A lifetime for a token was considered invalid
  /// </summary>
  TokenLifetimeInvalid = 76,
  /// <summary>
  /// The Token format was invalid
  /// </summary>
  TokenFormatInvalid = 77,
  /// <summary>
  /// The login view model is null, which means our FromBoxy did not understand it
  /// </summary>
  LoginViewModelIsNull = 78,
  /// <summary>
  /// We were expecting a JwtHeader and we did not get one
  /// </summary>
  JwtHeaderIsNull = 79,
  /// <summary>
  /// We did not get a disconnect view model from body when we expected it
  /// </summary>
  DisconnectViewModelIsNull = 80,
  /// <summary>
  /// When the user is not against an entity
  /// </summary>
  EntityNotMatched = 81,
  /// <summary>
  /// This is to say that the thing you are trying to add has already been added, so I cant do it
  /// </summary>
  AlreadyAdded = 82,
  /// <summary>
  /// The Search term was expected and it was null or empty
  /// </summary>
  SearchTermMissing = 83,
  /// <summary>
  /// The date range is invalid
  /// </summary>
  DateRangeInvalid = 84,
  /// <summary>
  /// the registration number (abn, acn or other type) is missing when expected
  /// </summary>
  RegistrationNumberMissing = 85,
  /// <summary>
  /// The UID of the person was missing was cant match
  /// </summary>
  UidMissing = 86,
  /// <summary>
  /// The Object is in the wrong state for that action to be completed.
  /// </summary>
  WrongState = 87,
  /// <summary>
  /// The card type (or other type) is not suitable for that action to be completed
  /// </summary>
  WrongType = 88,
  /// <summary>
  /// The Mandate is cancelled
  /// </summary>
  MandateCancelled = 89,
  /// <summary>
  /// The Mandate has expired
  /// </summary>
  MandateExpired = 90,
  /// <summary>
  /// The Mandate Failed
  /// </summary>
  MandateFailed = 91,
  /// <summary>
  /// This person is missing a required role and cannot perform that action
  /// </summary>
  RoleMissing = 92,
  /// <summary>
  /// There is no address registered against this object and I am expecting one, so as its missing I can't continue the process
  /// </summary>
  AddressMissing = 93,
  /// <summary>
  /// When examining a url if we think its not valid we will raise this error
  /// </summary>
  UrlInvalid = 94,
  /// <summary>
  /// The phone number is not valid in some way
  /// </summary>
  PhoneNumberIsInvalid = 95,
  /// <summary>
  /// This is where we have got some bad pagination values and we refuse to honour this request
  /// </summary>
  BadPagination = 96,
  /// <summary>
  /// The type is missing, we cant work out what to do
  /// </summary>
  TypeMissing = 97,
  /// <summary>
  /// This could mean that accountNumber, or sortcode, or another field like country is missing that is essential for getting a bank account setup
  /// </summary>
  BankDetailsMissing = 98,
  /// <summary>
  /// Certain functions can only be performed in certain environments, if its not permitted in this environment this will be the error code you get back
  /// </summary>
  WrongEnvironment = 99,
  /// <summary>
  /// This entity has no funds allocated, therefore we cannot proceed
  /// </summary>
  NoFundsAllocated = 100,
  /// <summary>
  /// If we were to allocate money at that level it would exceed the available
  /// </summary>
  AllocationExceeded = 101,
  /// <summary>
  /// We cant find an email for this thing (person, entity whatever) which means we have no way of connecting with them
  /// </summary>
  NoEmail = 102,
  /// <summary>
  /// A scope is missing
  /// </summary>
  ScopeMissing = 103,
  /// <summary>
  /// The pin has not been provided
  /// </summary>
  PinMissing = 104,
  /// <summary>
  /// The pin has already expired
  /// </summary>
  PinExpired = 105,
  /// <summary>
  /// That pin has already been verified
  /// </summary>
  AlreadyVerified = 106,
  /// <summary>
  /// No primary contact, i.e. nobody defined as the person to whom emails should do
  /// </summary>
  NoPrimaryContact = 107,
  /// <summary>
  /// This expiring link has already been completed
  /// </summary>
  ExpiringLinkCompleted = 108,
  /// <summary>
  /// The provider is missing therefore we cannot proceed with this request, usually refers to an oauth process
  /// </summary>
  ProviderMissing = 109,
  /// <summary>
  /// the open auth provider requested is not currently supported by this system
  /// </summary>
  ProviderNotSupported = 110,
  /// <summary>
  /// We cannot accept these credentials
  /// </summary>
  CredentialsInvalid = 111,
  /// <summary>
  /// A path to a file was missing
  /// </summary>
  PathEmpty = 112,
  /// <summary>
  /// This has not been added to the external system as we might expect, this could mean that an action has not yet been taken we cannot proceed
  /// </summary>
  NotAdded = 113,
  /// <summary>
  /// You do not hve the rights to access that data
  /// </summary>
  InsufficientPriviliges = 114,
  /// <summary>
  /// the file type provided was not one we were expecting so we are going to reject it
  /// </summary>
  UnsupportedFileType = 115,
}
