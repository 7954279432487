import { Box, Center, Heading, Flex, Image } from 'native-base';

import waveImage from 'assets/images/wave.png';
import PublicLayout from 'components/layouts/Public';

export const Expired = (): JSX.Element => (
  <PublicLayout>
    <Center>
      <Box
        testID="link-expired"
        display="flex"
        flexDirection={{ sm: 'column', md: 'row' }}
        pt={{ sm: '32px', md: '64px' }}
        alignItems={{ sm: 'center', md: 'flex-start' }}
      >
        <Heading
          testID="expired-heading"
          pb={{ sm: '32px', md: '0' }}
          pr={{ sm: '0', md: '64px' }}
        >
          Sorry, your link has expired...
        </Heading>
        <Flex
          testID="expired-icon"
          alignItems="center"
          justify="center"
          alignSelf={{ base: 'unset', lg: 'flex-end' }}
          mt="16px"
        >
          <Image
            width={{ base: '285px', md: '400px', xl: '440px' }}
            height={{ base: '85px', md: '118', xl: '129px' }}
            src={waveImage}
            alt="waveImage"
          />
        </Flex>
      </Box>
    </Center>
  </PublicLayout>
);
