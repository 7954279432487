import { Button, Flex, Modal, Row, Column, CloseIcon } from 'native-base';
import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';

import IconButton from 'components/inline/IconButton';
import SignOut from 'components/inline/SignOut';
import { obHelpCentre as helpCentreRoute } from 'components/layouts/Authorized/routes';
import NotificationLink from 'components/pages/Notifications/NotificationLink';
import EarPhoneIcon from 'components/svgs/ear-phone';
import HamburgerIcon from 'components/svgs/hamburger';
import MainLogo from 'components/svgs/logo';

const BurgerNav = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // component teardown
  useEffect(() => {
    return () => {
      setMenuOpen(false);
    };
  }, []);

  const svgSize = {
    width: '36px',
    height: '36px',
  };

  /* istanbul ignore next */
  const overlayStyle =
    Platform.OS === 'web'
      ? {
          position: 'fixed',
        }
      : {};

  return (
    <Column
      position="fixed"
      top="0"
      left="0"
      w="100%"
      display={{ base: 'flex', md: 'none' }}
      testID="burger-menu"
    >
      <Row
        alignItems="center"
        bg="primary.600"
        px={4}
        py={2}
        w="100%"
        justifyContent={'space-between'}
      >
        <Row alignItems="center">
          <Button
            w={10}
            p={1}
            testID="burger-nav-button"
            bg="transparent"
            onPress={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? (
              <CloseIcon style={{ fontSize: 20, color: 'tertiary.50' }} />
            ) : (
              <HamburgerIcon height="18px" width="18px" />
            )}
          </Button>
          <NotificationLink showIcon />
        </Row>

        <MainLogo height="28px" width="26px" />
      </Row>

      <Modal
        isOpen={menuOpen}
        size="full"
        // Special case for this because Nativebase used React Native ViewProps Types which doesnt allow 'position: fixed'
        /* eslint-disable */
        //@ts-ignore
        _overlay={{ style: overlayStyle }}
        _backdrop={{ display: 'none' }}
        testID={'burger-menu'}
      >
        <Modal.Content h="100%" maxH="100%" mb="0" mt="111px" testID="menu">
          <Modal.Body>
            <Flex flexDir="column" px={4} pt={4} pb="200px">
              <IconButton
                testID="help-center-nav-button"
                onPress={() => {
                  navigate(helpCentreRoute);
                  setMenuOpen(false);
                }}
                icon={<EarPhoneIcon {...svgSize} />}
                text="Help Centre"
                onFocus={() => setMenuOpen(false)}
              />

              <SignOut
                color="warmGray.600"
                fontSize="lg"
                svgWidth={svgSize.width}
                svgHeight={svgSize.height}
              />
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Column>
  );
};

export default BurgerNav;
