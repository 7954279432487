import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import BlobDto from 'models/blob';

import type { RootState } from 'store';

export type ReceiptsState = {
  hasMoreReceipts: boolean;
  receipts: BlobDto[];
  currentPage: number;
  receiptsToDownload: number[];
};

export type BlobUpdatePayload = {
  id: number;
  receipt: BlobDto;
};

export type ReceiptsToDownloadPayload = {
  ids: number[];
};

const slice = createSlice({
  name: 'receipts',
  initialState: {
    hasMoreReceipts: true,
    receipts: [],
    currentPage: 0,
    receiptsToDownload: [],
  } as ReceiptsState,
  reducers: {
    setReceipts: (state, { payload: receipts }: PayloadAction<BlobDto[]>) => {
      state.receipts = [...receipts];
    },
    resetCurrentPage: state => {
      state.currentPage = 0;
    },
    resetReceipts: state => {
      state.receipts = [];
      state.currentPage = 0;
      state.hasMoreReceipts = true;
    },
    setHasMoreReceipts: (
      state,
      { payload: hasMoreReceipts }: PayloadAction<boolean>,
    ) => {
      state.hasMoreReceipts = hasMoreReceipts;
    },
    incrementCurrentPage: state => {
      state.currentPage++;
    },
    updateReceipt: (
      state,
      { payload: { id, receipt } }: PayloadAction<BlobUpdatePayload>,
    ) => {
      const receiptIndex = state.receipts.findIndex(item => item.blobId === id);
      state.receipts[receiptIndex] = receipt;
    },
    setReceiptsToDownload: (
      state,
      { payload: { ids } }: PayloadAction<ReceiptsToDownloadPayload>,
    ) => {
      state.receiptsToDownload = ids;
    },
  },
});

export const {
  setReceipts,
  resetReceipts,
  resetCurrentPage,
  setHasMoreReceipts,
  incrementCurrentPage,
  setReceiptsToDownload,
  updateReceipt,
} = slice.actions;
export default slice.reducer;
export const fetchReceipts = (state: RootState): ReceiptsState =>
  state.receipts;
