import { ReactNode, useEffect } from 'react';

import { useLogoutUser } from 'components/hooks/useLogoutUser';
import AuthedLoadingStatesCtxt from 'components/providers/Authenticated/contexts/loadingStates';
import {
  removeDuplicateTransaction,
  removeHiddenTransaction,
} from 'components/util/transactionFilters';
import { CardDto } from 'models/card';
import { Roles } from 'models/enums/roles';
import PersonDto from 'models/person';
import { usePersonSummaryMutation } from 'network/person';
import { useGetReceiptsMutation } from 'network/receipts';
import { useGetTransactionsMutation } from 'network/transactions';
import ApiResponse from 'network/util/api-response';
import { useAppDispatch } from 'store/hooks';
import { useCards } from 'store/hooks/cards';
import { usePerson } from 'store/hooks/person';
import { setCards } from 'store/slices/cards';
import { setCurrentCard } from 'store/slices/currentCard';
import { setCurrentCardIndex } from 'store/slices/currentCardIndex';
import { setEntity } from 'store/slices/entity';
import { setLedgers } from 'store/slices/ledgers';
import { setUnreadNotiCount } from 'store/slices/notification';
import { setPerson } from 'store/slices/person';
import { setHasMoreReceipts, setReceipts } from 'store/slices/receipts';
import {
  setTransactions,
  setHasMoreTransactions,
  incrementCurrentPage,
} from 'store/slices/transactions';

interface Props {
  children: ReactNode;
}

export default function AuthenticatedProvider({
  children,
}: Props): JSX.Element {
  const [
    personSummary,
    {
      isError: isLoadingError,
      isLoading: isLoading,
      isSuccess: personLoadingSuccess,
    },
  ] = usePersonSummaryMutation();
  const [
    getTransactions,
    {
      isLoading: isLoadingTransactions,
      isError: isLoadingErrorTransactions,
      isSuccess: transactionsLoadingSuccess,
    },
  ] = useGetTransactionsMutation();
  const [getReceipts, { isLoading: isLoadingReceipts }] =
    useGetReceiptsMutation();
  const { logoutUser } = useLogoutUser();

  const dispatch = useAppDispatch();
  const { person } = usePerson();
  const { cards } = useCards();
  const fetchSize = 10;

  function dispatchSetPersonCards(cards: CardDto[]) {
    dispatch(setCurrentCard(cards[0]));
    dispatch(setCurrentCardIndex(0));
    dispatch(setCards(cards));
  }

  async function loadTransactions(person: PersonDto) {
    if (person.cards.length > 0) {
      const res = await getTransactions({
        LedgerId: person.ledger.id,
        Page: 0,
        PageSize: fetchSize,
        SearchTerm: null,
        From: null,
        To: null,
        hasReceipts: null,
      }).unwrap();
      const newTransaction = removeDuplicateTransaction(
        removeHiddenTransaction(res.data.items),
      );
      dispatch(setHasMoreTransactions(res.data.items.length === fetchSize));
      dispatch(setTransactions(newTransaction));
      dispatch(incrementCurrentPage());
    }
  }

  async function loadReceipts(person: PersonDto) {
    if (person.roles.includes(Roles.Owner)) {
      const res = await getReceipts({
        username: null,
        ledgerId: null,
        page: 0,
        pageSize: fetchSize,
        searchTerm: null,
        from: null,
        to: null,
      }).unwrap();

      if (res.success && res.data) {
        dispatch(setHasMoreReceipts(res.data.items.length === fetchSize));
        dispatch(setReceipts(res.data.items));
      }
    }
  }

  function setAuthenticatedState(person: PersonDto) {
    dispatch(setLedgers(person.ledgers));
    dispatch(setPerson(person));
    dispatch(setEntity(person.entity));
    dispatchSetPersonCards(person.cards);
    dispatch(setUnreadNotiCount(person.unreadNotifications));
    loadTransactions(person);
    loadReceipts(person);
  }

  useEffect(() => {
    async function getPerson() {
      const response = await personSummary()
        .unwrap()
        .catch(
          /* istanbul ignore next */
          (error: ApiResponse<string>) => {
            /* istanbul ignore next */
            return error;
          },
        );

      if (!response.success) {
        logoutUser();
        return;
      }

      const person = response.data as PersonDto;
      setAuthenticatedState(person);
    }

    !person &&
      Array.isArray(cards) &&
      cards.length === 0 &&
      !isLoading &&
      !isLoadingTransactions &&
      !personLoadingSuccess &&
      !transactionsLoadingSuccess &&
      !isLoadingError &&
      !isLoadingErrorTransactions &&
      !isLoadingReceipts &&
      getPerson();
  }, []);

  return (
    <AuthedLoadingStatesCtxt.Provider
      value={{
        isLoading: isLoading || isLoadingTransactions,
        isLoadingError: isLoadingError || isLoadingErrorTransactions,
      }}
    >
      {children}
    </AuthedLoadingStatesCtxt.Provider>
  );
}
