import * as React from 'react';
import { Svg, SvgProps, Circle, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={25} viewBox="0 0 24 25" fill="none" {...props}>
    <Circle cx={12} cy={12.951} r={12} fill="#F59197" />
    <Path
      d="M6.94 15.89a1.5 1.5 0 1 0 2.12 2.122L6.94 15.89Zm9.62-5.378a1.5 1.5 0 0 0-2.12-2.121l2.12 2.12Zm-7.5 7.5 7.5-7.5-2.12-2.121-7.5 7.5 2.12 2.12Z"
      fill="#fff"
    />
    <Path
      d="M16.56 15.89a1.5 1.5 0 0 1-2.12 2.122l2.12-2.122Zm-9.62-5.378A1.5 1.5 0 1 1 9.06 8.39l-2.12 2.12Zm7.5 7.5-7.5-7.5L9.06 8.39l7.5 7.5-2.12 2.12Z"
      fill="#fff"
    />
  </Svg>
);

export default SvgComponent;
