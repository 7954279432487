import { createContext, useContext } from 'react';

import LogglyTracker from './tracker';

// boundary type this is the data that will get passed from actual components
export type FunctionData = Record<string, unknown> | string;
// type NonSyntheticError = Record<string, unknown>

type ContextProps = {
  error: (err: unknown, data: FunctionData, once?: boolean) => void;
  info: (data: FunctionData, once?: boolean) => void;
  warn: (data: FunctionData, once?: boolean) => void;
  instance: LogglyTracker;
  providers: Record<string, unknown>;
};

const context = createContext<ContextProps>({} as ContextProps);
export default context;

export const Consumer = context.Consumer;
export const Provider = context.Provider;

export const useLoggly = (): ContextProps => useContext(context);

// export const withLoggly = (Component) => function logglyConsumer(props: React.FC):JSX.Element {
//   return (
//     <Consumer>
//       {(loggly) => (
//         <Component loggly={loggly} {...props} />
//       )}
//     </Consumer>
//   )};
