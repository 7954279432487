import {
  Flex,
  Box,
  IFlexProps,
  VStack,
  HStack,
  ChevronDownIcon,
  ChevronUpIcon,
  Stagger,
} from 'native-base';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { btnLinkprops } from 'components/layouts/Dashboard/talons/useDashboard';

import IconButton from '../IconButton';

export interface IconLinkProps extends IFlexProps {
  path?: string;
  color?: string;
  activeColor?: string;
  icon?: JSX.Element;
  text?: string;
  subLinks?: btnLinkprops[];
}

const NavLink = ({
  path,
  color = 'white',
  activeColor = 'warmGray.700',
  icon,
  text,
  testID,
  subLinks,
  ...props
}: IconLinkProps): JSX.Element => {
  const [isHovered, setIsHover] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const checkChildLinkIsActive =
    subLinks &&
    subLinks.length !== 0 &&
    subLinks.find(link => link.path === location.pathname)
      ? true
      : false;
  useEffect(() => {
    checkChildLinkIsActive && setMenuOpen(true);
    return () => {
      setMenuOpen(false);
    };
  }, [location]);

  // component teardown
  useEffect(() => {
    return () => {
      setIsHover(false);
      setMenuOpen(false);
    };
  }, []);

  const isActive = location.pathname === path || checkChildLinkIsActive;

  const textColor = isActive ? activeColor : isHovered ? 'warmGray.700' : color;

  return (
    <>
      <HStack>
        <Flex
          w="220px"
          h="44px"
          bg={isActive ? 'red.200' : 'none'}
          px={7}
          alignItems="center"
          flexDir="row"
          {...props}
        >
          <IconButton
            flex={1}
            testID={testID}
            color={textColor}
            activeColor={textColor}
            isActive={isActive}
            onHoverIn={() => {
              setIsHover(true);
            }}
            onHoverOut={() => {
              setIsHover(false);
            }}
            onPress={() => {
              if (subLinks) {
                setMenuOpen(!menuOpen);
              } else {
                path && navigate(path);
              }
            }}
            icon={icon}
            text={text}
            fontSize="18px"
            fontFamily="GTPressuraPro"
            iconRight={
              subLinks &&
              (menuOpen ? (
                <ChevronUpIcon color={textColor} ml="auto" />
              ) : (
                <ChevronDownIcon color={textColor} ml="auto" />
              ))
            }
          />
        </Flex>

        <VStack
          w="16px"
          h="44px"
          alignItems="flex-end"
          bg={isActive ? 'red.200' : 'none'}
        >
          <Box
            w={0}
            h={0}
            borderTopWidth="11px"
            borderTopStyle="solid"
            borderTopColor="transparent"
            borderBottomWidth="11px"
            borderBottomStyle="solid"
            borderBottomColor="transparent"
            borderRightWidth="8px"
            borderRightStyle="solid"
            borderRightColor="white"
          />
          <Box
            w={0}
            h={0}
            borderTopWidth="11px"
            borderTopStyle="solid"
            borderTopColor="transparent"
            borderBottomWidth="11px"
            borderBottomStyle="solid"
            borderBottomColor="transparent"
            borderRightWidth="8px"
            borderRightStyle="solid"
            borderRightColor="white"
          />
        </VStack>
      </HStack>

      {menuOpen && (
        <Stagger
          visible={menuOpen}
          initial={{
            opacity: 0,
            translateY: -34,
          }}
          animate={{
            translateY: 0,
            opacity: 1,
          }}
        >
          {subLinks &&
            subLinks.map(subProps => {
              const subLinkActive = location.pathname === subProps.path;
              return (
                <Flex
                  key={subProps.testID}
                  w="200px"
                  ml="10px"
                  h="44px"
                  bg={subLinkActive ? 'warmGray.700' : 'none'}
                  pl="18px"
                  borderRadius="8px"
                  alignItems="center"
                  flexDir="row"
                  mb={3}
                >
                  <IconButton
                    testID={subProps.testID}
                    color={color}
                    activeColor={subLinkActive ? color : activeColor}
                    isActive={subLinkActive}
                    onPress={() => {
                      subProps.path && navigate(subProps.path);
                    }}
                    text={subProps.text}
                    fontSize="18px"
                    fontFamily="GTPressuraPro"
                  />
                </Flex>
              );
            })}
        </Stagger>
      )}
    </>
  );
};

export default memo(NavLink);
