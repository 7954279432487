import { endpoints as cardEndpoints } from './card';
import { endpoints as entityEndpoints } from './entity';
import { endpoints as limitsEndpoints } from './limits';
import { endpoints as logglyEndpoints } from './loggly';
import { endpoints as paymentEndpoints } from './payment';
import { endpoints as personEndpoints } from './person';
import { endpoints as signUpEndpoints } from './signUp';
import { endpoints as statementEndpoints } from './statement';
import { endpoints as transactionsEndpoints } from './transactions';
import { endpoints as usersEndpoints } from './users';

export const handlers = [
  ...logglyEndpoints,
  ...usersEndpoints,
  ...personEndpoints,
  ...cardEndpoints,
  ...transactionsEndpoints,
  ...signUpEndpoints,
  ...entityEndpoints,
  ...statementEndpoints,
  ...paymentEndpoints,
  ...limitsEndpoints,
];
