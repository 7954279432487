import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { GpsTransactionDto as Transaction } from 'models/gpsTransaction';
import prepareHeaders from 'network/util/prepare-headers';

import { GPS_TRANSACTION_API_BASE } from './apiNamespaces';

export interface Data {
  total: number;
  items: Transaction[];
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const transaction = createApi({
  reducerPath: 'gpsTransactionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GPS_TRANSACTION_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getTransaction: builder.mutation<
      {
        success: boolean;
        data: Transaction;
      },
      {
        transactionId: number;
      }
    >({
      query: ({ transactionId }) => ({
        url: `details/${transactionId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTransactionMutation, reducer, middleware } = transaction;
