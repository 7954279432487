import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';

export type AuthState = {
  token: string | null;
  authenticated: boolean;
  guid: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { token: null, authenticated: false } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      state.token = token;
    },
    setAuthenticated: (
      state,
      { payload: authenticated }: PayloadAction<boolean>,
    ) => {
      state.authenticated = authenticated;
    },
  },
});

export const { setCredentials, setAuthenticated } = slice.actions;

export default slice.reducer;

export const selectCurrentToken = (state: RootState): string | null =>
  state.auth.token;

export const selectAuthenticated = (state: RootState): boolean =>
  state.auth.authenticated;
