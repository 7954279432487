import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';
export type CurrentCardIndexState = number | null;

const slice = createSlice({
  name: 'currentCardIndex',
  initialState: null as CurrentCardIndexState,
  reducers: {
    setCurrentCardIndex: (
      state,
      { payload: currentCardIndex }: PayloadAction<number>,
    ) => (state = currentCardIndex),
  },
});

export const { setCurrentCardIndex } = slice.actions;
export default slice.reducer;
export const selectCurrentCardIndex = (state: RootState): number | null => {
  return state.currentCardIndex;
};
