import {
  IButtonProps,
  ITextProps,
  Text,
  Flex,
  useToken,
  Pressable,
  Spacer,
  Spinner,
  Box,
} from 'native-base';
import { useState, useEffect, cloneElement, memo } from 'react';

export interface IIconButtonProps extends IButtonProps {
  text?: string;
  icon?: JSX.Element;
  iconRight?: JSX.Element;
  color?: string;
  iconColor?: string;
  activeColor?: string;
  isActive?: boolean;
  iconGap?: number;
  iconSize?: number | string;
}

const IconButton = ({
  children,
  text,
  color = 'warmGray.600',
  activeColor = 'primary.700',
  iconColor,
  icon,
  iconRight,
  iconSize,
  onHoverIn,
  onHoverOut,
  isActive = false,
  flexDir = 'row',
  justifyContent,
  _text,
  iconGap = 4,
  isLoading,
  isDisabled,
  ...props
}: IIconButtonProps): JSX.Element => {
  const [isHovered, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsHover(false);
    };
  }, []);

  const [activeIconColor, baseIconColor] = useToken('colors', [
    activeColor,
    iconColor || color,
  ]);
  const {
    fontSize = 'lg',
    fontWeight = 'normal',
    fontStyle,
    fontFamily = 'GTPressuraPro',
  } = { ...props };

  const newIconSize = iconSize ?? fontSize;
  const [resolvedIconSize] = useToken('fontSizes', [newIconSize as string]);

  const textProps = (): ITextProps => {
    return { fontSize, fontWeight, fontStyle, fontFamily };
  };

  const customIcon = (cloneIcon: JSX.Element) =>
    cloneElement(cloneIcon, {
      color: isHovered || isActive ? activeIconColor : baseIconColor,
      fill: isHovered || isActive ? activeIconColor : baseIconColor,
      width: resolvedIconSize,
      height: resolvedIconSize,
    });

  const disabled = isLoading || isDisabled;

  return (
    <Pressable
      display="flex"
      flexDir="row"
      justifyContent="flex-start"
      onHoverIn={() => {
        onHoverIn && onHoverIn();
        setIsHover(true);
      }}
      onHoverOut={() => {
        onHoverOut && onHoverOut();
        setIsHover(false);
      }}
      p="0"
      m="0"
      borderColor={isHovered || isActive ? activeColor : color}
      borderRadius={8}
      isDisabled={disabled}
      opacity={disabled ? 0.5 : 1}
      {...props}
    >
      <Flex
        flexDir={flexDir}
        alignItems="center"
        justifyContent={justifyContent}
        w="100%"
      >
        {icon && customIcon(icon)}
        {icon && (text || children) && <Spacer w={iconGap} flexGrow="0" />}
        {(text || children) && (
          <Text
            {..._text}
            {...textProps()}
            color={isHovered || isActive ? activeColor : color}
          >
            {text}
            {children}
          </Text>
        )}
        {iconRight && (text || children) && <Spacer w={iconGap} flexGrow="0" />}
        {isLoading ? (
          <Spinner />
        ) : (
          <Box>{iconRight && customIcon(iconRight)}</Box>
        )}
      </Flex>
    </Pressable>
  );
};

export default memo(IconButton);
