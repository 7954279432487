import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import NoteDto from 'models/note';
import prepareHeaders from 'network/util/prepare-headers';

import { MEMO_API_BASE } from './apiNamespaces';

export interface AddReceiptNoteRequest {
  description: string;
  gpsTransactionId: number;
}

export interface AddReceiptNoteResponse {
  success: boolean;
  data: NoteDto;
}

export interface DeleteReceiptNoteResponse {
  success: boolean;
}

export const receiptMemo = createApi({
  reducerPath: 'receiptMemoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: MEMO_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    addReceiptNote: builder.mutation<
      AddReceiptNoteResponse,
      AddReceiptNoteRequest
    >({
      query: data => ({
        url: '',
        method: 'POST',
        body: data,
      }),
    }),
    removeReceiptNote: builder.mutation<
      DeleteReceiptNoteResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddReceiptNoteMutation,
  useRemoveReceiptNoteMutation,
  reducer,
  middleware,
} = receiptMemo;
