import {
  Tag,
  Box,
  Menu,
  Row,
  Spacer,
  Text,
  Link,
  Divider,
  ChevronRightIcon,
} from 'native-base';

import IconButton from 'components/inline/IconButton';
import IconLink from 'components/inline/IconLink';
import { notification as notificationRoute } from 'components/layouts/Authorized/routes';
import EnvelopeIcon from 'components/svgs/envelope';
import EnvelopeOpenedIcon from 'components/svgs/envelope-opened';
import NotificationIcon from 'components/svgs/notification';
import { EventTypes, getDisplayTitle } from 'models/enums/event-type';

import useNotificationLink from './talons/useNotificationLink';

const NotificationLink = ({
  showIcon,
}: {
  showIcon?: boolean;
}): JSX.Element => {
  const {
    unreadNotiCount,
    notificationList,
    loadNotification,
    handleViewAllLink,
    handleView,
    setMenuOpen,
    menuOpen,
  } = useNotificationLink();

  return (
    <Box testID="notification-link">
      {showIcon ? (
        <Box display={{ base: 'flex', md: 'none' }}>
          <IconLink
            mr={4}
            testID="notification-link-tag"
            icon={<NotificationIcon />}
            text=""
            path={notificationRoute}
            activeColor="tertiary.50"
            color="tertiary.50"
            fontSize="20px"
          />
          <Tag
            testID="unread-notification-tag"
            bgColor="red.300"
            _text={{ color: 'gray.50', fontSize: '11px' }}
            borderColor="gray.50"
            position="absolute"
            display={unreadNotiCount ? 'flex' : 'none'}
            borderRadius="11px"
            px={1}
            h={4}
            minH={0}
            minW={0}
            top="-10%"
            left="35%"
          >
            {unreadNotiCount}
          </Tag>
        </Box>
      ) : (
        <Box>
          <IconButton
            testID="notification-link-button"
            justifyContent="center"
            fontWeight="normal"
            borderWidth={2}
            bg="tertiary.50"
            px={4}
            py={2}
            fontSize={14}
            onPress={() => {
              loadNotification();
            }}
            iconRight={<NotificationIcon />}
          >
            Notifications
          </IconButton>

          <Box
            p="4px"
            right="12px"
            top="12px"
            borderRadius="100px"
            borderWidth="1px"
            borderColor="tertiary.50"
            bgColor="red.300"
            position="absolute"
            display={unreadNotiCount ? 'flex' : 'none'}
          />

          <Menu
            w="300px"
            closeOnSelect={false}
            isOpen={menuOpen}
            _backdrop={{ testID: 'backdrop' }}
            onClose={() => {
              setMenuOpen(false);
            }}
            testID="notificationsMenu"
            trigger={triggerProps => {
              return <Box {...triggerProps}></Box>;
            }}
            pb="0"
          >
            <Row
              justifyContent="space-between"
              px="12px"
              py="16px"
              testID="noti-dropDown"
            >
              <Text>{unreadNotiCount} New Notifications</Text>

              <Link
                display="flex"
                alignItems="center"
                justifyContent="center"
                testID="view-all-link"
                onPress={() => handleViewAllLink()}
              >
                <Text fontWeight="bold" color="primary.600" marginRight="16px">
                  View All
                </Text>
              </Link>
            </Row>
            <Divider />

            {notificationList.map((item, index) => {
              const isClickable = [
                EventTypes.StatementReady,
                EventTypes.LimitApplied,
                EventTypes.ConnectionDeletedInXero,
                EventTypes.ConnectionError,
              ].includes(item.type);
              return (
                <Menu.Item
                  key={index}
                  testID="noti-item-id"
                  borderBottomWidth="1px"
                  borderColor="warmGray.300"
                  isDisabled={!isClickable}
                  onPress={() => handleView(item)}
                >
                  <Row my="8px" alignItems="center" flex="1">
                    <Box bg="warmGray.200" p="8px" borderRadius="100px">
                      {item.readAt ? (
                        <EnvelopeOpenedIcon width="16px" height="16px" />
                      ) : (
                        <EnvelopeIcon width="16px" height="16px" />
                      )}
                    </Box>
                    <Spacer w="16px" flexGrow="0" />
                    <Box>{getDisplayTitle(item.type)}</Box>
                    <Spacer w="16px" flexGrow="0" />
                    <Box ml="auto" w="20px">
                      {isClickable && <ChevronRightIcon />}
                    </Box>
                  </Row>
                </Menu.Item>
              );
            })}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default NotificationLink;
