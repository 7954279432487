import { Factory } from 'fishery';

import { Processor } from 'models/enums/processor';
import LedgerDto, { EntityLedgerDto, LedgerCardDto } from 'models/ledger';

export const LedgerCardFactory = Factory.define<LedgerCardDto>(
  ({ sequence }) => ({
    id: sequence,
    ledgerId: sequence,
    ledgerDetails: `ledgerDetails ${sequence}`,
    cardId: sequence,
    cardDetails: `cardDetails ${sequence}`,
  }),
);

export const EntityLedgerFactory = Factory.define<EntityLedgerDto>(
  ({ sequence }) => ({
    id: sequence,
    userId: sequence,
    name: `ledger-${sequence}`,
    externalReference: `externalReference ${sequence}`,
    limit: 5000,
    balance: 10000,
    available: 3300,
    pending: 0,
    usage: 3000,
    email: 'test@getcape.io',
    mobile: '0422222222',
  }),
);

export const LedgerFactory = Factory.define<LedgerDto>(({ sequence }) => ({
  id: sequence,
  amount: 123.5,
  limit: 456.5,
  balance: 789.5,
  accountName: `accountName ${sequence}`,
  accountNumber: '12345678',
  goCardlessReference: 'gcr',
  processor: Processor.Rails,
  available: 3300,
  pending: 0,
}));

export default LedgerFactory;
