import { PhoneNumberUtil } from 'google-libphonenumber';

import { countryData } from './country-data';

export interface validatePassowrd {
  minLengthPass: boolean;
  includeUppercase: boolean;
  includeLowercase: boolean;
  includeNumber: boolean;
  includeSpecial: boolean;
  minTestPass: boolean;
  isValid: boolean;
}

export const isPassword = (value?: string): validatePassowrd => {
  const rules = {
    upper: /[A-Z]+/,
    lower: /[a-z]+/,
    number: /[0-9]+/,
    special: /[@#$%^&+!=?-]+/,
  };

  let includeUppercase = false;
  let includeLowercase = false;
  let includeNumber = false;
  let includeSpecial = false;
  let minLengthPass = false;
  let minTestPass = false;
  let isValid = false;

  if (value) {
    minLengthPass = value.length >= 8 && value.length <= 32 ? true : false;
    includeUppercase = rules.upper.test(value);
    includeLowercase = rules.lower.test(value);
    includeNumber = rules.number.test(value);
    includeSpecial = rules.special.test(value);

    const matchesCount =
      Number(includeUppercase) +
      Number(includeLowercase) +
      Number(includeNumber) +
      Number(includeSpecial);

    minTestPass = matchesCount >= 3 ? true : false;
    isValid = minTestPass && minLengthPass;
  }

  return {
    minLengthPass,
    includeUppercase,
    includeLowercase,
    includeNumber,
    includeSpecial,
    minTestPass,
    isValid,
  };
};

export const isPasswordMatch = (
  value: string,
  matchValue: string,
): boolean | string => {
  if (matchValue === value) {
    return true;
  } else {
    return 'Password does not match';
  }
};

export const isPostCode = (value: string): boolean | string => {
  const matcher = /^[0-9]*$/;

  if (value.length !== 4) {
    return 'postcode must be 4 digits long';
  }

  return matcher.test(value) ? true : 'postcode can only contain numbers';
};

export const getCountry = (value: string): string => {
  const country = countryData.find(item => item.code === value)?.name ?? '';

  return country;
};

// Function that will validate an email address to ensure it, at the very least, includes an @ symbol and a dot
export const isEmail = (value: string, errorMsg?: string): boolean | string => {
  const emailRegex = /(.+)@(.+)\.(.+){2,}/;

  return emailRegex.test(value)
    ? true
    : errorMsg
      ? errorMsg
      : 'email address format is incorrect';
};

export const isTelephone = (
  value: string,
  countryCode: string,
): boolean | string => {
  const phoneNumberCleaned = value.replace(/ /g, '');
  const phoneUtil = PhoneNumberUtil.getInstance();
  const PhoneNumberObject = phoneUtil.parseAndKeepRawInput(
    phoneNumberCleaned,
    countryCode,
  );
  const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
    PhoneNumberObject,
    countryCode,
  );

  return isValidPhoneNumber ? true : 'not valid for number for region';
};

export const isSortCode = (value: string): boolean | string => {
  return /^\d{6}$|\d{3}-\d{3}$|\d{2}-\d{2}-\d{2}$/.test(value)
    ? true
    : 'Your BSB should be 6 digits long';
};

export const isAccountNumber = (value: string): boolean | string => {
  return /^\d{6,10}$/.test(value)
    ? true
    : 'Your account number should be 6 to 10 digits long';
};

export const isLast4CardNumber = (
  value: string,
  errorMsg: string,
): boolean | string => {
  return /^\d{4}$/.test(value) ? true : errorMsg;
};
