import { useToast } from 'native-base';
import { useEffect, useRef } from 'react';

import { useCurrentGlobalToast } from 'store/hooks/globalToast';
import { GlobalToastMsg } from 'store/slices/globalToast';

import ErrToast from './errorLoggerTemplate';

interface Props {
  children: JSX.Element;
}

export default function GlobalToastProvider({ children }: Props): JSX.Element {
  const { message, counter } = useCurrentGlobalToast();
  const Toast = useToast();
  const toastIdRef = useRef();

  function showToast(message: GlobalToastMsg) {
    toastIdRef.current = Toast.show({
      duration: 3000,
      render: () => (
        <ErrToast
          title={message.title}
          description={message.description}
          closeToast={closeToast}
        />
      ),
    });
  }

  function closeToast() {
    Toast.close(toastIdRef.current);
  }

  useEffect(() => {
    message && showToast(message);
  }, [counter, message]);

  useEffect(() => {
    return () => {
      Toast.closeAll();
    };
  }, []);

  return children;
}
