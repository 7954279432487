import { Center, Spacer, Heading, Button, VStack } from 'native-base';
import { useNavigate } from 'react-router-dom';

import BoxCard from 'components/inline/BoxCard/NbBoxCard';

export const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <Heading
        textAlign={{ base: 'center', md: 'unset' }}
        size="xl"
        mb="24px"
        testID="load-error-header"
      >
        Page Loading Error
      </Heading>
      <BoxCard>
        <Center>
          <VStack>
            <Spacer h="16px" />
            <Heading size="md" paddingLeft="16px" testID="error-page">
              Oops, there was a problem
            </Heading>
            <Spacer h="16px" />
            <Button testID="try-again" onPress={() => navigate(0)}>
              Try Again
            </Button>
          </VStack>
        </Center>
      </BoxCard>
    </>
  );
};
