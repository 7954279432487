import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={35} height={35} viewBox="0 0 35 35" fill="none" {...props}>
    <Path d="M2.917 24.427V22.24h13.125v2.187H2.917Zm0-11.667v-2.187h13.125v2.187H2.917Zm17.791 16.151-1.53-1.53 4.082-4.048-4.083-4.046 1.531-1.532 4.084 4.047 4.047-4.047 1.53 1.532-4.046 4.046 4.047 4.047-1.531 1.532-4.047-4.047-4.084 4.047Zm3.428-13.38-4.959-4.958 1.531-1.531 3.391 3.39 6.453-6.453 1.531 1.568-7.947 7.984Z" />
  </Svg>
);

export default SvgComponent;
