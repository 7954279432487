import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { XeroConnectionDto } from 'models/xero-connection';
import { XeroCreateConnectionDto } from 'models/xero-create-connection';
import prepareHeaders from 'network/util/prepare-headers';

import { XERO_API_BASE, XERO_REDIRECT_URI } from './apiNamespaces';
import ApiResponse from './util/api-response';

export const xeroConnection = createApi({
  reducerPath: 'xeroConnectionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: XERO_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getXeroConnection: builder.mutation<ApiResponse<XeroConnectionDto>, void>({
      query: () => ({
        url: 'connection',
        method: 'GET',
        params: {
          redirectUri: XERO_REDIRECT_URI,
        },
      }),
    }),
    postXeroConnection: builder.mutation<
      ApiResponse<XeroConnectionDto>,
      XeroCreateConnectionDto
    >({
      query:
        /* istanbul ignore next */
        body => ({
          url: 'connection',
          method: 'POST',
          body,
        }),
    }),
    deleteXeroConnection: builder.mutation<ApiResponse<unknown>, void>({
      query:
        /* istanbul ignore next */
        () => ({
          url: 'connection',
          method: 'DELETE',
        }),
    }),
  }),
});

export const {
  useGetXeroConnectionMutation,
  usePostXeroConnectionMutation,
  useDeleteXeroConnectionMutation,
  reducer,
  middleware,
} = xeroConnection;
