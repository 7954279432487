import { Messages } from '@openreplay/tracker';
import { useEffect } from 'react';

import { useCurrentOpenReplay } from 'store/hooks/openReplay';
import { OpenReplayMsg } from 'store/slices/openReplay';

interface Props {
  children: JSX.Element;
}

declare global {
  /* eslint-disable */
  // @ts-ignore
  interface Window {
    __OPENREPLAY__: any;
  }
}

export default function OpenReplayProvider({ children }: Props): JSX.Element {
  const { message, counter, username } = useCurrentOpenReplay();

  function sendMessage(message: OpenReplayMsg) {
    window.__OPENREPLAY__ &&
      window.__OPENREPLAY__.app.send(
        Messages.Fetch(
          message.method,
          message.url,
          message.requestData,
          message.responseData,
          message.status,
          message.fulfilledTimeStamp,
          message.timeTaken,
        ),
      );
  }

  function setUser(username: string) {
    window.__OPENREPLAY__ && window.__OPENREPLAY__.setUserID(username);
  }

  useEffect(() => {
    message && sendMessage(message);
  }, [counter, message]);

  useEffect(() => {
    username && setUser(username);
  }, [username]);

  return children;
}
