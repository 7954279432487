import { useState, useEffect } from 'react';

import IconButton from 'components/inline/IconButton';
import DashboardIcon from 'components/svgs/downloadIcon';
import { NULL_LEDGER_ID } from 'config/constants';
import {
  transactionsExportEndpoint,
  gpsTransactionsExportEndpoint,
} from 'config/endpoints';
import { Processor } from 'models/enums/processor';
import { useGetOTCMutation as useGetGpsOTCMutation } from 'network/authentication';
import { useGetOTCMutation } from 'network/transactions';

export interface DownloadCSVProps {
  searchParams?: string | null;
  processor?: Processor;
}

export const DownloadCSV = ({
  searchParams = '',
  processor = Processor.Rails,
}: DownloadCSVProps) => {
  const [getOTC, { isLoading: otcIsLoading }] = useGetOTCMutation();
  const [geGpsOTC, { isLoading: gpsOtcIsLoading }] = useGetGpsOTCMutation();

  const [downloadCount, setDownloadCount] = useState<number>(0);
  const [downloadReady, setDownloadReady] = useState<boolean>(true);

  // component teardown
  useEffect(() => {
    return () => {
      setDownloadCount(0);
      setDownloadReady(true);
    };
  }, []);

  async function buttonPress() {
    const otcToken =
      processor === Processor.Rails
        ? await getOTC().unwrap()
        : await geGpsOTC().unwrap();
    let downloadURL;
    let params;

    otcToken.success && applyToken();

    function applyToken() {
      const token = otcToken.data.accessToken;
      const endpoint =
        processor === Processor.Rails
          ? transactionsExportEndpoint
          : gpsTransactionsExportEndpoint;

      params = `${token}/${NULL_LEDGER_ID}${searchParams}`;
      downloadURL = endpoint;
      downloadURL = `${endpoint}/${params}`;

      initiateDownload(downloadURL);

      setDownloadReady(true);
    }

    function initiateDownload(downloadURL: string) {
      setDownloadCount(downloadCount + 1);
      setDownloadReady(false);

      const anchor = document.createElement('a');
      anchor.rel = 'nofollow noreferrer';
      anchor.target = '_blank';
      anchor.download = 'transactions.csv';
      anchor.href = downloadURL;
      anchor.click();
      anchor.remove();
    }
  }

  return (
    <>
      <IconButton
        testID="download-transactions-button"
        onPress={buttonPress}
        disabled={otcIsLoading || gpsOtcIsLoading || !downloadReady}
        fontSize="md"
        fontFamily="GTPressuraPro"
        fontWeight="normal"
        py={2}
        px={{ base: 0, md: 6 }}
        flexDir="row-reverse"
        icon={<DashboardIcon width="5px" />}
        text="Download CSV"
        justifyContent="center"
        borderWidth={{ base: 0, lg: 2 }}
        _text={{ display: { base: 'none', lg: 'inline' } }}
      />
      <input
        data-counter={downloadCount.toString()}
        data-testid="hidden-download-counter"
        type="hidden"
        value={0}
      />
    </>
  );
};
export default DownloadCSV;
