import {
  API_BASE_URL as apiBase,
  LOGGLY_API_BASE_URL as logglyApiBase,
  XERO_REDIRECT_URI as xeroRedirectUri,
} from 'config/environment';

const apiVersion = 'v1.1';

export const USERS_API_BASE = `${apiBase}/${apiVersion}/users`;
export const SIGN_UP_API_BASE = `${apiBase}/${apiVersion}/signup`;
export const PERSON_API_BASE = `${apiBase}/${apiVersion}/person`;
export const ENTITY_API_BASE = `${apiBase}/${apiVersion}/entity`;
export const EVENTS_API_BASE = `${apiBase}/${apiVersion}/events`;
export const BUDGETS_API_BASE = `${apiBase}/${apiVersion}/budgets`;
export const OAUTH_API_BASE = `${apiBase}/${apiVersion}/oauth`;
export const CARD_API_BASE = `${apiBase}/${apiVersion}/card`;
export const RECEIPTS_API_BASE = `${apiBase}/${apiVersion}/receipts`;
export const RECEIPT_API_BASE = `${apiBase}/${apiVersion}/receipt`;
export const EXPENSE_API_BASE = `${apiBase}/${apiVersion}/accounting`;
export const TRANSACTIONS_API_BASE = `${apiBase}/${apiVersion}/transactions`;
export const GPS_TRANSACTIONS_API_BASE = `${apiBase}/${apiVersion}/gpstransactions`;
export const GPS_TRANSACTION_API_BASE = `${apiBase}/${apiVersion}/gpstransaction`;
export const VERIFICATION_API_BASE = `${apiBase}/${apiVersion}/verification`;
export const MEMO_API_BASE = `${apiBase}/${apiVersion}/memo`;
export const NOTIFICATIONS_API_BASE = `${apiBase}/${apiVersion}/notifications`;
export const NOTIFICATION_API_BASE = `${apiBase}/${apiVersion}/notification`;
export const LOGGLY_API_BASE = `${logglyApiBase}`;
export const STATEMENT_API_BASE = `${apiBase}/${apiVersion}/statement`;
export const STATEMENTS_API_BASE = `${apiBase}/${apiVersion}/statements`;
export const LIMITS_API_BASE = `${apiBase}/${apiVersion}/limits`;
export const PAYMENTS_API_BASE = `${apiBase}/${apiVersion}/payments`;
export const AUTHENTICATION_API_BASE = `${apiBase}/${apiVersion}/authentication`;
export const TAG_API_BASE = `${apiBase}/${apiVersion}/tag`;
export const TAGS_API_BASE = `${apiBase}/${apiVersion}/tags`;
export const XERO_API_BASE = `${apiBase}/${apiVersion}/xero`;
export const XERO_REDIRECT_URI = xeroRedirectUri;

export default apiBase;
