import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={20} viewBox="0 0 24 20" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 1.515c0 .837-.672 1.515-1.5 1.515h-21C.672 3.03 0 2.352 0 1.515 0 .678.672 0 1.5 0h21c.828 0 1.5.678 1.5 1.515ZM24 10c0 .837-.672 1.515-1.5 1.515h-21C.672 11.515 0 10.837 0 10c0-.837.672-1.515 1.5-1.515h21c.828 0 1.5.678 1.5 1.515ZM24 18.485c0 .837-.672 1.515-1.5 1.515h-21C.672 20 0 19.322 0 18.485c0-.837.672-1.515 1.5-1.515h21c.828 0 1.5.678 1.5 1.515Z"
      fill="#FBFAF6"
    />
  </Svg>
);

export default SvgComponent;
