import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      clipRule="evenodd"
      d="M5.29 6.694C5.29 2.986 8.308 0 12.014 0a6.735 6.735 0 0 1 6.727 6.726c0 3.71-3.02 6.695-6.727 6.695A6.735 6.735 0 0 1 5.29 6.694Zm6.725-4.479a4.514 4.514 0 0 0-4.51 4.511c0 2.488 2.02 4.48 4.51 4.48a4.514 4.514 0 0 0 4.511-4.512c0-2.487-2.02-4.479-4.51-4.479ZM22.932 24H1.1c-.326 0-.66-.151-.853-.433a1.163 1.163 0 0 1-.22-.977c.162-.75.356-1.539.582-2.26.292-.926.648-1.783 1.037-2.571.976-1.963 2.332-3.328 3.854-3.981 1.525-.655 3.206-.592 4.816.277 1.082.59 2.286.59 3.368 0 1.61-.869 3.29-.932 4.816-.277 1.522.653 2.878 2.017 3.854 3.98.389.79.745 1.681 1.036 2.571v.002c.227.72.421 1.472.583 2.26.072.347 0 .7-.22.976a.981.981 0 0 1-.82.433Zm-5.123-8.015c-.978-.46-2.04-.43-3.094.144-1.743.94-3.623.94-5.365 0-1.054-.574-2.125-.597-3.107-.132-.986.467-1.896 1.432-2.608 2.856v.002c-.349.67-.634 1.408-.89 2.219l-.004.014-.007.013c-.09.166-.146.351-.196.553h18.951c-.018-.063-.038-.125-.058-.19l-.001-.002c-.04-.124-.08-.255-.113-.394-.255-.808-.54-1.545-.887-2.213l-.001-.002c-.728-1.441-1.639-2.406-2.62-2.869Z"
    />
  </Svg>
);

export default SvgComponent;
