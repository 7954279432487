import { Box, Container, Flex, Text, Image, Link, Spacer } from 'native-base';

import AppleAppStoreButton from 'assets/images/apple-app-store.png';
import AppleExample from 'assets/images/apple-example.png';
import PlayStoreButton from 'components/svgs/play-store-button';
import { AXLE_BUILD, AXLE_VERSION } from 'config/environment';

// import styles from "./Base.module.css"

const versionString = AXLE_VERSION;
const appBuildString = AXLE_BUILD;

interface BaseProps {
  header?: JSX.Element | JSX.Element[] | null;
  content?: JSX.Element | JSX.Element[] | null;
  version?: string;
  appBuild?: string;
  bg?: string;
  isLogin?: boolean;
}

export default function Base({
  header,
  content,
  version = versionString,
  appBuild = appBuildString,
  bg = 'white',
  isLogin = false,
}: BaseProps): JSX.Element {
  return (
    <Box
      width={'100%'}
      height={{ base: isLogin ? 'auto' : '100vh', lg: '100vh' }}
      maxWidth="1920px"
      margin="auto"
      bg={bg}
      testID="Base"
    >
      {isLogin ? (
        <Flex flexDirection={{ base: 'column', lg: 'row' }} flex={1}>
          <Box flex={{ lg: 1 }}>
            <Container p="0px" maxWidth="100%" w="100%" bg={bg} zIndex="unset">
              <Box flexDirection={'row'} w="100%">
                {header}
              </Box>
              {appBuild && version && (
                <Flex
                  width="100%"
                  justify="center"
                  align="center"
                  pt="3.2px"
                  position="absolute"
                  top="2"
                >
                  <Text
                    testID="build-string"
                    fontSize="lg"
                    color="gray.200"
                  >{`${appBuild} - ${version}`}</Text>
                </Flex>
              )}
            </Container>
            <Container
              p="0px"
              maxWidth="100%"
              bg={bg}
              position="initial"
              flex={1}
            >
              {content}
            </Container>
          </Box>
          <Box
            flex={1}
            backgroundColor="#F6F3EB"
            paddingX={{ base: 4, lg: '48px' }}
            paddingTop={10}
            alignItems="center"
            overflow={'hidden'}
          >
            <Box
              width={{ lg: '50%' }}
              alignItems={{ base: 'center', lg: 'flex-start' }}
              alignSelf={{ lg: 'flex-start' }}
            >
              <Text
                fontFamily={'GTPressuraPro'}
                fontSize={{ base: '20px', lg: '36px' }}
                lineHeight={{ lg: '52px' }}
                fontWeight={700}
                textAlign={{ base: 'center', lg: 'left' }}
              >
                Introducing, the brand new Cape mobile app.
              </Text>

              <Spacer height={8} />

              <Text
                fontFamily={'CircularStdBook'}
                fontSize={{ lg: '18px' }}
                marginBottom={{ lg: 4 }}
                lineHeight={'30px'}
              >
                Now available to download on Google Play and the iOS App Store.
              </Text>

              <Spacer height={8} />

              <Box
                flexWrap={'wrap'}
                flexDirection={'row'}
                justifyContent={{ base: 'center', sm: 'flex-start' }}
              >
                <Link
                  href={
                    'https://apps.apple.com/au/app/cape-expense-management/id1639860424'
                  }
                  isExternal
                  marginLeft={{ lg: '-12px' }}
                  padding={3}
                >
                  <Image
                    src={AppleAppStoreButton}
                    height={'47px'}
                    width={'146px'}
                    alt={'App Store Image'}
                  />
                </Link>
                <Link
                  href={
                    'https://play.google.com/store/apps/details?id=com.capelabs.Axle'
                  }
                  isExternal
                  marginLeft={{ lg: '-12px' }}
                >
                  <PlayStoreButton />
                </Link>
              </Box>
            </Box>
            <Box
              height={{ base: '300px', lg: '340px' }}
              width="400px"
              paddingTop={6}
            >
              <Image
                width={{ base: '185.05', lg: '320px' }}
                height={{ base: '295', lg: '600px' }}
                position={'absolute'}
                right={{ base: '26%', lg: '0' }}
                top={{ lg: '13%' }}
                resizeMode={'contain'}
                zIndex={2}
                src={AppleExample}
                alt="Dashboard demo image"
              />
            </Box>
          </Box>
        </Flex>
      ) : (
        <>
          <Container p="0px" maxWidth="100%" w="100%" bg={bg} zIndex="unset">
            {header}
            {appBuild && version && (
              <Flex
                width="100%"
                justify="center"
                align="center"
                pt="3.2px"
                position="absolute"
                top="2"
              >
                <Text
                  testID="build-string"
                  fontSize="lg"
                  color="gray.200"
                >{`${appBuild} - ${version}`}</Text>
              </Flex>
            )}
          </Container>
          <Container p="0px" maxWidth="100%" bg={bg} position="initial">
            {content}
          </Container>
        </>
      )}
    </Box>
  );
}
