import { useMemo } from 'react';

import { selectJwtTokenValid } from 'store/slices/jwtTokenValid';

import { useAppSelector } from '.';

export const useJwtTokenValid = (): { jwtTokenValid: boolean } => {
  const jwtTokenValid = useAppSelector(selectJwtTokenValid);

  return useMemo(() => ({ jwtTokenValid }), [jwtTokenValid]);
};
