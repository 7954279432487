import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type AddressDto from 'models/address';
import type LedgerDto from 'models/ledger';
import type Person from 'models/person';
import type { RootState } from 'store';

export type PersonState = Person | null;

const slice = createSlice({
  name: 'person',
  initialState: null as PersonState,
  reducers: {
    setPerson: (state, { payload: person }: PayloadAction<Person>) =>
      (state = person),
    setPersonsLedger: (
      state,
      { payload: ledger }: PayloadAction<LedgerDto>,
    ) => {
      state && (state.ledger = ledger);
      return state;
    },
  },
});

export const { setPerson, setPersonsLedger } = slice.actions;
export default slice.reducer;
export const selectPerson = (state: RootState): Person => {
  return state.person;
};

export const selectFirstAddress = (addressType: number) => {
  return (state: RootState): AddressDto | null => {
    if (state.person) {
      return state.person.addresses.filter(
        (address: AddressDto) => address.addressType === addressType,
      )[0];
    } else {
      return null;
    }
  };
};

export const selectFirstBankAccount = (state: RootState): LedgerDto | null => {
  if (state.person && state.person.bankAccounts) {
    return state.person.bankAccounts[0];
  } else {
    return null;
  }
};
