export enum PersonStates {
  /// These are the envisaged types
  None = 0,
  /// this state indicates that this person is a pep
  IsPep = 1,
  /// this states that this person is the ultimate business owner
  IsUbo = 2,
  /// When a use sets the communication type to be sms or email, this flag is set. This means we are IN progress,,, this flag only will be removed WHEN the person for the user
  /// completes a successful 2fa and then this flag is removed. This flag will also be returned to the front end and then used to control if a redirect should be done back
  /// to the 2fa setup page. Thus closing a hole in the process
  IsIn2FaSetup = 4,
  /// This is done when we want to force a 2fa process next time a person logs in regardless of the ipaddress,useragent or time
  TwoFaRequired = 8,
}
