import jwtDecode from 'jwt-decode';
import { useCookies } from 'react-cookie';

import { DISABLE_SECURE_COOKIE } from 'config/environment';

const secureCookieDisabled = DISABLE_SECURE_COOKIE;

export const useLoginUser = () => {
  const [, setCookie] = useCookies(['jwt', 'username']);

  const loginUser = (jwtFromBackend: string) => {
    const decodedRefreshJwt: Record<string, unknown> =
      jwtDecode(jwtFromBackend);
    setCookie(
      'username',
      decodedRefreshJwt['urn:HelloCapeCustomClaim:Username'],
      {
        path: '/',
        secure: !secureCookieDisabled,
        domain: process.env.REACT_APP_DOMAIN,
      },
    );
    setCookie('jwt', jwtFromBackend, {
      path: '/',
      secure: !secureCookieDisabled,
      domain: process.env.REACT_APP_DOMAIN,
    });
  };

  return {
    loginUser,
  };
};
