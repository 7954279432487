import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';

export type GlobalToastState = {
  message?: GlobalToastMsg;
  counter: number;
};

export type GlobalToastMsg = {
  title: string;
  description: string;
};

const slice = createSlice({
  name: 'globalToast',
  initialState: { counter: 0 } as GlobalToastState,
  reducers: {
    show: (state, { payload }: PayloadAction<GlobalToastMsg>) => {
      state.message = payload;
      state.counter += 1;
    },
  },
});

export const { show } = slice.actions;

export default slice.reducer;

export const selectCurrentGlobalToast = (state: RootState): GlobalToastState =>
  state.globalToast;
