import { Flex } from 'native-base';

import { Roles } from 'models/enums/roles';

import BaseLayout from '../Base';

interface AuthorizedProp {
  children: JSX.Element | JSX.Element[] | null;
  header?: JSX.Element;
  footer?: JSX.Element;
  roles?: Roles[];
  burgerNav?: JSX.Element;
}

const Authorized = ({
  children,
  header,
  footer,
  burgerNav,
}: AuthorizedProp): JSX.Element => {
  return (
    <BaseLayout
      header={
        header || (
          <Flex
            testID="Authorized"
            width="100%"
            bg="white"
            zIndex={{ base: 3, md: 'unset' }}
            pt={{ base: '56px', md: '24px' }}
            px="96px"
            justifyContent={{
              base: 'space-between',
              md: 'flex-end',
            }}
            alignContent="center"
            direction="row"
          >
            {burgerNav}
          </Flex>
        )
      }
      content={children}
      footer={footer}
    />
  );
};
export default Authorized;
