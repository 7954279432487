import CryptoJS from 'crypto-js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface VersionSyncProps {
  children: JSX.Element;
  bypass?: boolean;
}

const bypassSync =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function VersionSync({
  children,
  bypass = bypassSync,
}: VersionSyncProps): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    if (bypass) return;

    let jsonString: string;
    let versionSha: string;
    const origin = window.location.origin;
    const currentVersionSha = localStorage.getItem('versionSha');

    function initiateUpdate() {
      localStorage.setItem('versionSha', versionSha);
      window.location.reload();
    }

    fetch(`${origin}/asset-manifest.json`).then(response => {
      if (!response.ok) {
        const err = new Error(
          `HTTP status code: ${response.status}: There was an issue`,
        );
        console.error(err);
      } else {
        response.json().then(json => {
          jsonString = JSON.stringify(json);
          versionSha = CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);

          !!currentVersionSha && localStorage.setItem('versionSha', versionSha);
          currentVersionSha !== versionSha && initiateUpdate();
        });
      }
    });
  }, [location, bypass]);

  return children;
}
