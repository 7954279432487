import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { timeout } from 'components/util/async-timer';
import { CardDto } from 'models/card';
import { CardStatus } from 'models/enums/card-status';
import prepareHeaders from 'network/util/prepare-headers';

import { CARD_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export interface OrderCardRequest {
  AddressId: number;
  NameOnCard: string;
}

export interface ReplaceCardRequest {
  CardId: number;
  AddressId: number;
}

export interface GetCardRequest {
  id: number;
}

export interface GetCardResponse {
  success: boolean;
  data: CardDto;
}

export interface ActivateCardResponse {
  success: boolean;
}

export interface ActivateCardRequest {
  token: string;
  cardId: number;
}

export interface SuspendCardRequest {
  id: number;
}

export interface SuspendCardResponse {
  success: boolean;
}

export interface RetrievePinRequest {
  token: string;
  cardId: number;
}

export const card = createApi({
  reducerPath: 'cardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CARD_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    orderCard: builder.mutation<ApiResponse<CardDto>, OrderCardRequest>({
      query: body => ({
        url: '/order',
        method: 'POST',
        body,
      }),
    }),
    replaceCard: builder.mutation<ApiResponse<CardDto>, ReplaceCardRequest>({
      query: body => ({
        url: '/replace',
        method: 'POST',
        body,
      }),
    }),
    getCardDetails: builder.mutation<GetCardResponse, GetCardRequest>({
      query: ({ id }) => ({
        url: `detail/${id}`,
        method: 'GET',
      }),
    }),
    activateCard: builder.mutation<ActivateCardResponse, ActivateCardRequest>({
      query: data => ({
        url: 'activate',
        method: 'POST',
        body: data,
      }),
    }),
    activatingCardSaga: builder.mutation<GetCardResponse, GetCardRequest>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        async function refetchCardFunc(
          time: number,
          callback?: () => Promise<
            { data: GetCardResponse } | { error: FetchBaseQueryError }
          >,
        ): Promise<{ data: GetCardResponse } | { error: FetchBaseQueryError }> {
          const refetchCard = await baseQuery({
            url: `detail/${arg.id}`,
            method: 'GET',
          });

          const passingStatuses = [CardStatus.Active, CardStatus.Suspended];

          const refetchResponse = refetchCard.data as GetCardResponse;
          const cardStatus = refetchResponse.data?.status || -1;

          if (
            (refetchResponse.success && passingStatuses.includes(cardStatus)) ||
            !callback
          ) {
            return { data: refetchCard.data as GetCardResponse };
          } else {
            await timeout(time);
            return await callback();
          }
        }

        return await refetchCardFunc(500, () =>
          refetchCardFunc(1500, () => refetchCardFunc(5000)),
        );
      },
    }),
    suspendCard: builder.mutation<SuspendCardResponse, SuspendCardRequest>({
      query: ({ id }) => ({
        url: `suspend/${id}`,
        method: 'POST',
      }),
    }),
    retrievePin: builder.mutation<ApiResponse<string>, RetrievePinRequest>({
      query: body => ({
        url: 'pin',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useOrderCardMutation,
  useReplaceCardMutation,
  useGetCardDetailsMutation,
  useActivateCardMutation,
  useActivatingCardSagaMutation,
  useSuspendCardMutation,
  useRetrievePinMutation,
  reducer,
  middleware,
} = card;
