import { rest } from 'msw';

import {
  cardDetailEndpoint,
  cardPinEndpoint,
  cardActivateEndpoint,
  cardOrderEndpoint,
  cardSuspendEndpoint,
  cardReplaceEndpoint,
} from 'config/endpoints';
import { CardFactory } from 'mocks/factories/card';
import { PersonFactory } from 'mocks/factories/person';
import { CardStatus } from 'models/enums/card-status';
import { store } from 'store';

const pin = 123456;
// @ts-ignore
const { id: cardId } = PersonFactory.build().cards[0];

const isMyCardsPage =
  window && window.location.pathname.includes('/dashboard/my-cards');

export const endpoints = [
  rest.get(cardDetailEndpoint(cardId), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        data: { ...CardFactory.build(), status: CardStatus.Active },
        success: true,
      }),
    );
  }),
  rest.post(cardPinEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({ success: true, data: pin }),
    );
  }),
  rest.post(cardActivateEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({ success: true }),
    );
  }),
  rest.get(cardDetailEndpoint(15), (req, res, ctx) => {
    const currentState = store.getState();
    const currentCardState = currentState.cards[0].status;
    const cardIsActive = currentCardState === CardStatus.Active;
    const cardIsActivating = currentCardState === CardStatus.Activating;
    const cardIsSuspending = currentCardState === CardStatus.Suspending;
    const cardIsSuspended = currentCardState === CardStatus.Suspended;

    let cardStatus = CardStatus.Active;

    if (isMyCardsPage) {
      if (cardIsActive) {
        cardStatus = CardStatus.Suspending;
      } else if (isMyCardsPage && cardIsSuspending) {
        cardStatus = CardStatus.Suspended;
      } else if (cardIsSuspended) {
        cardStatus = CardStatus.Activating;
      } else if (cardIsActivating) {
        cardStatus = CardStatus.Active;
      }
    }

    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        data: { ...CardFactory.build(), id: 15, status: cardStatus },
        success: true,
      }),
    );
  }),
  rest.post(cardOrderEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({ success: true }),
    );
  }),
  rest.post(cardSuspendEndpoint(15), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({ success: true }),
    );
  }),
  rest.post(cardReplaceEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        success: true,
        data: {
          ...CardFactory.build(),
          id: 100,
          status: CardStatus.AwaitingActivation,
          truncatedPan: '5431',
        },
      }),
    );
  }),
];
