import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BankFeed } from 'models/bank-feed';
import { BankFeedCandidate } from 'models/bank-feed-candidate';
import {
  XeroCreateBankFeedByAccountIdDto,
  XeroCreateBankFeedByAccountNumberDto,
} from 'models/xero-create-bank-feed';
import prepareHeaders from 'network/util/prepare-headers';

import { XERO_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export const xeroBankFeed = createApi({
  reducerPath: 'xeroBankFeedApi',
  baseQuery: fetchBaseQuery({
    baseUrl: XERO_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getBankFeed: builder.mutation<ApiResponse<BankFeed>, void>({
      query: () => ({
        url: 'bankfeed',
        method: 'GET',
      }),
    }),
    getCandidates: builder.mutation<ApiResponse<BankFeedCandidate[]>, void>({
      query: () => ({
        url: 'bankfeed/candidates',
        method: 'GET',
      }),
    }),
    createBankFeedByAccountId: builder.mutation<
      ApiResponse<unknown>,
      XeroCreateBankFeedByAccountIdDto
    >({
      query: body => ({
        url: 'bankfeed/byaccountid',
        method: 'POST',
        body,
      }),
    }),
    createBankFeedByAccountNumber: builder.mutation<
      ApiResponse<unknown>,
      XeroCreateBankFeedByAccountNumberDto
    >({
      query: body => ({
        url: 'bankfeed/byaccountnumber',
        method: 'POST',
        body,
      }),
    }),
    deleteBankFeed: builder.mutation<ApiResponse<unknown>, void>({
      query: () => ({
        url: 'bankfeed',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetBankFeedMutation,
  useGetCandidatesMutation,
  useCreateBankFeedByAccountIdMutation,
  useCreateBankFeedByAccountNumberMutation,
  useDeleteBankFeedMutation,
  reducer,
  middleware,
} = xeroBankFeed;
