import { useCookies } from 'react-cookie';

import { DISABLE_SECURE_COOKIE } from 'config/environment';
import { useAppDispatch } from 'store/hooks';
import { setJwtTokenValidity } from 'store/slices/jwtTokenValid';

export interface useLogoutUser {
  logoutUser: () => void;
}

export const useLogoutUser = (): useLogoutUser => {
  const disableSecureCookie = DISABLE_SECURE_COOKIE;
  const dispatch = useAppDispatch();
  const [, , removeCookie] = useCookies(['jwt', 'username', 'ddSkip']);
  const logoutUser = () => {
    removeCookie('jwt', {
      path: '/',
      secure: !disableSecureCookie,
      domain: process.env.REACT_APP_DOMAIN,
    });
    removeCookie('username', {
      path: '/',
      secure: !disableSecureCookie,
      domain: process.env.REACT_APP_DOMAIN,
    });

    removeCookie('ddSkip', {
      path: '/',
      domain: process.env.REACT_APP_DOMAIN,
    });

    dispatch(setJwtTokenValidity(true));
    dispatch({ type: 'reset' });

    /* eslint-disable */
    // @ts-ignore
    window.__OPENREPLAY__ && window.__OPENREPLAY__.stop();
  };

  return {
    logoutUser,
  };
};
