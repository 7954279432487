import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={20} height={21} viewBox="0 0 20 21" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.318 20.5C1.848 20.5 0 18.452 0 16.091V9.998a1.111 1.111 0 1 1 2.222 0v6.093c0 1.224.942 2.187 2.096 2.187h11.364c1.116 0 2.096-.923 2.096-2.187V9.998a1.111 1.111 0 1 1 2.222 0v6.093c0 2.464-1.948 4.409-4.318 4.409H4.318Zm10.48-12.287c.432.436.428 1.14-.008 1.571l-3.764 3.728c-.43.426-1.122.429-1.556.007L5.635 9.79a1.111 1.111 0 0 1 1.55-1.593l1.948 1.894v-8.48a1.111 1.111 0 1 1 2.222 0v8.446l1.872-1.853a1.111 1.111 0 0 1 1.571.008Z"
    />
  </Svg>
);

export default SvgComponent;
