import { useMemo } from 'react';

import { useAppSelector } from 'store/hooks';
import { selectCurrentCard } from 'store/slices/currentCard';

import { CardDto } from '../../models/card';

export const useCurrentCard = (): { currentCard: CardDto | null } => {
  const currentCard = useAppSelector(selectCurrentCard);

  return useMemo(() => ({ currentCard }), [currentCard]);
};
