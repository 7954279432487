import { useMemo } from 'react';

import { Processor } from 'models/enums/processor';
import LedgerDto from 'models/ledger';
import { useAppSelector } from 'store/hooks';
import {
  selectLedgers,
  selectGpsLedger,
  selectRailsLedger,
} from 'store/slices/ledgers';

type LedgerState = {
  mode: 'multi' | 'single';
  items: LedgerDto[];
  selectedProcessor: Processor;
  // currentLedger?: LedgerDto
};

export const useLedgers = (): { ledgerState: LedgerState } => {
  const ledgerState = useAppSelector(selectLedgers);
  return useMemo(() => ({ ledgerState }), [ledgerState]);
};

export const useGpsLedger = (): { ledger: LedgerDto } => {
  const ledger = useAppSelector(selectGpsLedger);
  return useMemo(() => ({ ledger }), [ledger]);
};

export const useRailsLedger = (): { ledger: LedgerDto } => {
  const ledger = useAppSelector(selectRailsLedger);
  return useMemo(() => ({ ledger }), [ledger]);
};
