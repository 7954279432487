import { rest } from 'msw';

import { makePaymentEndpoint } from 'config/endpoints';

export const endpoints = [
  rest.post(makePaymentEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({ success: true }),
    );
  }),
];
