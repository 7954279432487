import { useMemo } from 'react';

import { useAppSelector } from 'store/hooks';
import { selectEntity } from 'store/slices/entity';

import type { EntityDto } from 'models/entity';

export const useEntity = (): { entity: EntityDto | null } => {
  const entity = useAppSelector(selectEntity);
  return useMemo(() => ({ entity }), [entity]);
};

// export const useEntityPersons = (): { persons: EntityPersonDto[] | null } => {
//   const persons = useAppSelector(selectEntityPersons)
//   return useMemo(() => ({ persons }), [persons])
// }
