import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={21} height={12} viewBox="0 0 21 12" fill="none" {...props}>
    <Path
      d="M16.436 2.368C14.544 1.38 12.446.835 10.529.835c-1.917 0-4.015.545-5.906 1.533C2.61 3.418 1.028 4.89.158 6.508a.76.76 0 0 0 .296 1.024.743.743 0 0 0 1.008-.3c1.15-2.138 3.577-3.675 6.018-4.407a4.829 4.829 0 0 0-1.682 3.67c0 2.652 2.119 4.799 4.727 4.799 2.612 0 4.727-2.151 4.727-4.798a4.821 4.821 0 0 0-1.682-3.67c2.44.73 4.869 2.268 6.018 4.406a.735.735 0 0 0 1.008.3.76.76 0 0 0 .296-1.023c-.862-1.62-2.445-3.092-4.456-4.14Zm-5.907 6.109c-1.076 0-1.947-.88-1.951-1.973-.005-1.105.862-1.99 1.951-1.994.034 0 .065 0 .099.005a1.484 1.484 0 0 0-.099.535c.005.801.64 1.446 1.428 1.446a1.4 1.4 0 0 0 .524-.1c0 .03.004.056.004.087-.004 1.106-.866 1.99-1.956 1.994Z"
      fill="#979797"
    />
  </Svg>
);

export default SvgComponent;
