import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Processor } from 'models/enums/processor';
import LedgerDto from 'models/ledger';

import type { RootState } from 'store';

export type LedgerState = {
  mode: 'multi' | 'single';
  items: LedgerDto[];
  selectedProcessor: Processor;
};

const slice = createSlice({
  name: 'ledgersState',
  initialState: {
    mode: 'single',
    items: [],
    selectedProcessor: Processor.Gps,
  } as LedgerState,
  reducers: {
    setLedgers: (state, { payload: ledgers }: PayloadAction<LedgerDto[]>) => {
      containsRailsLedger(ledgers) &&
        containsGpsLedger(ledgers) &&
        (state.mode = 'multi');
      !containsGpsLedger(ledgers) &&
        (state.selectedProcessor = Processor.Rails);
      ledgers.length === 0 && (state.selectedProcessor = Processor.Gps);
      state.items = ledgers;
    },
    setSelectedProcessor: (
      state,
      { payload: processor }: PayloadAction<Processor>,
    ) => {
      state.selectedProcessor = processor;
    },
  },
});

function containsGpsLedger(ledgers: LedgerDto[]): boolean {
  return !!ledgers.find(ledger => ledger.processor === Processor.Gps);
}

function containsRailsLedger(ledgers: LedgerDto[]): boolean {
  return !!ledgers.find(ledger => ledger.processor === Processor.Rails);
}

export const { setLedgers, setSelectedProcessor } = slice.actions;
export default slice.reducer;
export const selectLedgers = (state: RootState): LedgerState => {
  return state.ledgers;
};

export const selectGpsLedger = (state: RootState): LedgerDto => {
  const gpsLedger = state.ledgers.items.find(
    (ledger: LedgerDto) => ledger.processor === Processor.Gps,
  );
  return gpsLedger;
};

export const selectRailsLedger = (state: RootState): LedgerDto => {
  const railsLedger = state.ledgers.items.find(
    (ledger: LedgerDto) => ledger.processor === Processor.Rails,
  );
  return railsLedger;
};
