export enum States {
  None = 0,
  InRails = 1,
  InGoCardless = 2,
  BankAccountSetup = 4,
  MandateSetup = 8,
  Unused1 = 16,
  Test = 32,
  Current = 64,
  Delinquent = 128,
  ChargeOff = 256,
  WriteOff = 512,
  Closed = 1024,
  Lost = 2048,
}
