import { Box, useBreakpointValue } from 'native-base';

import IconLink from 'components/inline/IconLink/MobileNavLink';
import {
  dashboard as dashboardRoute,
  transactions as transactionsRoute,
  myCards as myCardsRoute,
  people as personsRoute,
  receipts as ReceiptRoute,
} from 'components/layouts/Authorized/routes';
import ReceiptIcon from 'components/svgs/has-receipt';
import DashboardIcon from 'components/svgs/home';
import MycardIcon from 'components/svgs/my-card';
import PersonsIcon from 'components/svgs/people';
import TransactionIcon from 'components/svgs/transactions';
import { Roles } from 'models/enums/roles';

interface StickyNavProps {
  roles: Roles[];
}

const StickyNav = ({ roles }: StickyNavProps): JSX.Element => {
  const iconSize = {
    width: '20px',
    height: '20px',
  };

  const display = useBreakpointValue({ base: 'flex', md: 'none' });

  return (
    <Box
      testID="sticky-nav"
      zIndex="3"
      w="100%"
      py="3px"
      position="sticky"
      bottom="0"
      left="0"
      bg="primary.600"
      display={display}
      flexDir="row"
      height={roles ? '66px' : '66px'}
    >
      {roles ? (
        <>
          <IconLink
            path={dashboardRoute}
            testID="sticky-dashboard-link"
            icon={<DashboardIcon {...iconSize} />}
            text="Home"
            fontSize="12.8px"
            mx="3px"
            flex={1}
          />
          {roles && roles.includes(Roles.Owner) && (
            <IconLink
              path={personsRoute}
              testID="sticky-peoples-link"
              icon={<PersonsIcon {...iconSize} />}
              text="People"
              fontSize="12.8px"
              mx="3px"
              flex={1}
            />
          )}

          <IconLink
            path={transactionsRoute}
            icon={<TransactionIcon {...iconSize} />}
            text="Spend"
            fontSize="12.8px"
            mx="3px"
            flex={1}
          />

          {roles && roles.includes(Roles.Accountant) ? (
            <IconLink
              testID="my-cards-footer-link"
              path={ReceiptRoute}
              icon={<ReceiptIcon {...iconSize} />}
              text="Receipts"
              fontSize="12.8px"
              mx="3px"
              flex={1}
            />
          ) : (
            <IconLink
              testID="my-cards-footer-link"
              path={myCardsRoute}
              icon={<MycardIcon {...iconSize} />}
              text="My Card"
              fontSize="12.8px"
              mx="3px"
              flex={1}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};

export default StickyNav;
