export enum AddressTypes {
  Home = 1,
  Delivery = 2,
  Invoice = 3,
  Registered = 4,
  HeadOffice = 5,
  Remit = 6,
  Credit = 7,
  Other = 8,
  Work = 9,
}
