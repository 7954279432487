import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      clipRule="evenodd"
      d="M11.207.334a1.21 1.21 0 0 1 1.67 0L23.58 10.356c.267.25.42.608.42.985v11.34C24 23.41 23.438 24 22.746 24H14.55c-.693 0-1.255-.59-1.255-1.319v-4.044h-2.508v4.044c0 .729-.562 1.319-1.255 1.319H1.254C.562 24 0 23.41 0 22.681V11.43c0-.376.153-.735.42-.985L11.207.334Zm.835 2.75L2.51 12.02v9.343h5.77v-4.044c0-.729.561-1.319 1.254-1.319h5.018c.692 0 1.254.59 1.254 1.319v4.044h5.686v-9.43l-9.449-8.848Z"
    />
  </Svg>
);

export default SvgComponent;
