import { Factory } from 'fishery';

import { StatementDto } from 'models/statement';

import { AddressFactory } from './address';

export const StatementFactory = Factory.define<StatementDto>(() => ({
  entityId: 1,
  legalName: 'Stokenbrand PtyLtd',
  tradingName: 'Stokenbrand PtyLtd',
  registrationNumber: '29483728402',
  address: AddressFactory.build(),
  statementId: 1,
  from: '2021-04-01T13:07:04.054',
  fromString: '1 April 2021',
  to: '2022-05-06T13:07:04.054',
  toString: '6 May 2022',
  newCredits: 1500.0,
  newDebits: 1013.35,
  closingBalance: 3011.87,
  openingBalance: 2598.52,
  due: '2022-08-06T13:07:04.054',
  dueString: '6 August 2022',
  institutionName: 'WestPac',
  accountNumber: '39284575',
  routingNumber: '29-39-16',
  ledgers: [
    {
      accountName: 'John Penny',
      rows: [
        {
          id: 1,
          statementId: 1,
          ledgerId: 3,
          accountName: 'John Penny',
          cardId: 3,
          lastFour: '5267',
          amount: 2125.45,
          createdAt: '2021-04-05T13:07:04.054',
          description:
            'Visa Purchase - Receipt 173178 SHOUT BOTTLESHOPS SA SANDY BAY  Card 5267',
          credit: 0.0,
          debit: 70.0,
        },
        {
          id: 2,
          statementId: 2,
          ledgerId: 3,
          accountName: 'John Penny',
          cardId: 3,
          lastFour: 'sample string 5',
          amount: 2118.95,
          createdAt: '2021-04-08T13:07:04.054',
          description:
            'Visa Purchase - Receipt 173178 HILL STREET SANDY BAY SANDY BAY  Card 5267',
          credit: 0.0,
          debit: 6.5,
        },
      ],
    },
    {
      accountName: 'Sven von Sperl',
      rows: [
        {
          id: 3,
          statementId: 3,
          ledgerId: 5,
          accountName: 'Sven von Sperl',
          cardId: 1,
          lastFour: '3944',
          amount: 2042.12,
          createdAt: '2022-04-01T13:07:04.054',
          description:
            'ATM Withdrawal - Cash - Receipt 001347 WESTPACROSNY ROSN Card 3944',
          credit: 0.0,
          debit: 50.0,
        },
        {
          id: 4,
          statementId: 4,
          ledgerId: 5,
          accountName: 'Sven von Sperl',
          cardId: 2,
          lastFour: '3944',
          amount: 4042.12,
          createdAt: '2022-04-02T13:07:04.054',
          description: 'Osko Deposit - Receipt 235771',
          credit: 2000.0,
          debit: 0.0,
        },
      ],
    },
  ],
}));
