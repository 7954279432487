import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { OAUTH_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export interface OAuthData {
  token: string;
}

export interface OAuthResponse {
  data: OAuthData;
  success: boolean;
  code: number;
  message: string;
  details: string[];
  errors: Record<string, unknown>;
}

export interface OAuthRequest {
  authorizationToken: string;
  guid: string;
  provider: string;
}

export const oauth = createApi({
  reducerPath: 'oauthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: OAUTH_API_BASE,
    prepareHeaders: headers => {
      return headers;
    },
  }),
  endpoints: builder => ({
    setOAuthCredentials: builder.mutation<ApiResponse<OAuthData>, OAuthRequest>(
      {
        query: ({ authorizationToken, guid, provider }) => ({
          url: `${authorizationToken}/${guid}/${provider}`,
          method: 'POST',
        }),
      },
    ),
  }),
});

export const { useSetOAuthCredentialsMutation, reducer, middleware } = oauth;
