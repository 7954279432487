import './src/index.css';
import Tracker from '@openreplay/tracker';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { NativeBaseProvider, extendTheme } from 'native-base';
import React from 'react';
import 'react-native';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import OpenReplayProvider from 'components/providers/OpenReplay';

import App from './src/components/App';
import GlobalToastProvider from './src/components/providers/GlobalToast';
import LogglyProvider from './src/components/providers/Loggly/provider';
import { AXLE_BUILD, REACT_APP_USE_MOCK, GTM } from './src/config/environment';
import { worker } from './src/mocks/browser';
import themeOptions from './src/newTheme';
import { store } from './src/store';

if (REACT_APP_USE_MOCK) {
  worker.start();
}

const tracker = new Tracker({
  projectKey: 'wxxDRfD2KxvdTmLQBajA',
  ingestPoint: 'https://replay.getcape.io/ingest',
});

AXLE_BUILD?.includes('production') && tracker.start();

AXLE_BUILD?.includes('production') &&
  Sentry.init({
    dsn: 'https://3b46c894dced439786c0af6f308a38e3@sentry.getcape.io/2',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

const breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
};

const theme = extendTheme({
  ...themeOptions,
  breakpoints,
  fonts: {
    heading: 'CircularStdMedium',
    body: 'CircularStdBook',
  },
});

const logglyOptions = {
  key: true,
  session_id: 'axle-session-id',
  useDomainProxy: false,
  inputUrl: '',
  sendConsoleErrors: true,
};

const tagManagerArgs = {
  gtmId: GTM || 'REACT_APP_GTM',
};

TagManager.initialize(tagManagerArgs);

render(
  <LogglyProvider options={logglyOptions} providers={{}}>
    <CookiesProvider>
      <Provider store={store}>
        <NativeBaseProvider theme={theme}>
          <OpenReplayProvider>
            <GlobalToastProvider>
              <App />
            </GlobalToastProvider>
          </OpenReplayProvider>
        </NativeBaseProvider>
      </Provider>
    </CookiesProvider>
  </LogglyProvider>,
  document.getElementById('root'),
);
