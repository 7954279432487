import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { stringToBase64 } from 'components/util/base64';
import prepareHeaders from 'network/util/prepare-headers';

import { USERS_API_BASE } from './apiNamespaces';
import ApiResponse from './util/api-response';

export interface SetPasswordResponse {
  success: boolean;
}

export interface AuthResponse {
  token: string;
  code?: number;
  data?: {
    email: string;
    mobile: string;
    communicationType: number;
    guid: string;
  };
}

export interface StartLoginResponse {
  guid: string;
  username: string;
  provider: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface SetPasswordRequest {
  Guid: string;
  Password: string;
  DateOfBirth: string;
}

export interface ChangePasswordRequest {
  Username: string;
  OldPassword: string;
  NewPassword: string;
}

export interface ResetPasswordRequest {
  Username: string;
}

export const auth = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    startLogin: builder.mutation<ApiResponse<StartLoginResponse>, string>({
      query: email => ({
        url: `start/${stringToBase64(email)}`,
        method: 'GET',
      }),
    }),
    tokenRefresh: builder.mutation<AuthResponse, void>({
      query: () => ({
        url: 'refresh',
        method: 'POST',
      }),
    }),
    // send a email for reset password
    resetPassword: builder.mutation<SetPasswordResponse, ResetPasswordRequest>({
      query: data => ({
        url: 'resetpassword',
        method: 'POST',
        body: data,
      }),
    }),
    // reset a new password
    setPassword: builder.mutation<SetPasswordResponse, SetPasswordRequest>({
      query: data => ({
        url: 'setpassword',
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation<
      SetPasswordResponse,
      ChangePasswordRequest
    >({
      query: data => ({
        url: 'changepassword',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useStartLoginMutation,
  useTokenRefreshMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useChangePasswordMutation,
  reducer,
  middleware,
} = auth;
