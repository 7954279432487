import * as React from 'react';
import { Svg, SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={24} viewBox="0 0 18 24" fill="none" {...props}>
    <Path d="M16.168 1.737H1.833v19.012l2.346-1.487 2.153 2.047L9 19.318l2.668 1.991 2.154-2.047 2.346 1.487V1.737ZM1.833.688h14.335c.609 0 1.102.47 1.102 1.049v20.971l-3.308-2.097-2.205 2.097L9 20.651l-2.757 2.057-2.205-2.097L.73 22.708V1.737c0-.58.494-1.049 1.103-1.049ZM5.01 6.193a.538.538 0 0 1-.551-.524c0-.29.247-.524.551-.524h7.978c.305 0 .552.234.552.524 0 .29-.247.524-.552.524H5.011Z" />
    <Path d="M4.46 10.046c0 .29.247.524.551.524h7.978a.538.538 0 0 0 .552-.524.538.538 0 0 0-.552-.525H5.011a.538.538 0 0 0-.551.525Z" />
    <Path
      clipRule="evenodd"
      d="M15.438 19.457V2.425H2.562v17.032l1.71-1.084 2.119 2.015L9 18.44l2.609 1.948 2.118-2.015 1.71 1.084ZM0 1.737C0 .783.815 0 1.833 0h14.335C17.185 0 18 .783 18 1.737V24l-3.944-2.5-2.24 2.13L9 21.528 6.184 23.63l-2.24-2.13L0 24V1.737Zm3.73 3.932c0-.664.568-1.213 1.281-1.213h7.978c.713 0 1.281.549 1.281 1.213s-.567 1.212-1.28 1.212H5.01c-.713 0-1.281-.548-1.281-1.212Zm0 4.377c0-.664.568-1.213 1.281-1.213h7.978c.713 0 1.281.549 1.281 1.213s-.567 1.212-1.28 1.212H5.01c-.713 0-1.281-.548-1.281-1.212Z"
    />
  </Svg>
);

export default SvgComponent;
