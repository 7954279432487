import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import Transactions from 'models/transactions';
import prepareHeaders from 'network/util/prepare-headers';

import { TRANSACTIONS_API_BASE } from './apiNamespaces';
import ApiResponse, { ApiArrayData } from './util/api-response';

export interface GetTransactionsRequest {
  LedgerId: number | undefined;
  Page: number;
  PageSize: number;
  SearchTerm: string | null;
  From: string | null;
  To: string | null;
  hasReceipts: boolean | null;
}

export interface Data {
  total: number;
  items: Transactions[];
}

export interface Otc {
  success: boolean;
  data: {
    accessToken: string;
  };
}

export interface GetTransactionsResponse {
  success: boolean;
  data: Data;
}

export const transactions = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TRANSACTIONS_API_BASE,
    prepareHeaders,
  }),
  endpoints: builder => ({
    getTransactions: builder.mutation<
      ApiResponse<ApiArrayData<Transactions>>,
      GetTransactionsRequest
    >({
      query: data => ({
        url: '',
        method: 'POST',
        body: data,
      }),
    }),
    getOTC: builder.mutation<Otc, void>({
      query: () => ({
        url: 'accessToken',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetTransactionsMutation,
  useGetOTCMutation,
  reducer,
  middleware,
} = transactions;
