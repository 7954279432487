import { rest } from 'msw';

import {
  entityBankAccountEndpoint,
  entityMandateEndpoint,
  entityDetailEndpoint,
  entityPersonsEndpoint,
  entityUsageEndpoint,
} from 'config/endpoints';
import { getEntityDetailsFx } from 'mocks/fixtures/getEntityDetails';
import { getEntityPersonsFx } from 'mocks/fixtures/getEntityPersons';

export const endpoints = [
  rest.get(entityDetailEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json(getEntityDetailsFx()),
    );
  }),
  rest.post(entityPersonsEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json(getEntityPersonsFx()),
    );
  }),
  rest.post(entityBankAccountEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        success: true,
        data: {
          accountHolderName: 'John Penney',
          accountNumber: '12345678',
          sortCode: '200000',
          merchantName: 'Cape',
          directDebitUserId: '123456',
          name: 'Cape',
          pdfLink:
            'https://mandate-previews.gocardless.com/?token=eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIn0..GkMl7hO3aZavaIVs.m1NtX3NYXZET5gLWnRaip-sfskTLl9XKf6fD8mcUCMN3iqBr7Xkv6sqFJW8GJ9bFP7HqqWkfHzx_sJRoCS9BDvQa5dfpc2pVEn4UMzuZxu_nkpL8K2oKYgNAbqCwvQ8maqtUh1hqb8KzcQv-1HhfQqKPK_WZ_dQa6wFHeOxCJCRsQnshE8Sr6JxZY8lYOBCdxHpmzBcIek-G-QYN1EzDFdFTqzTuLxK7DquZryIYN37d2wReQU51L7qchkOZWdB8ZECj7-m0wMRFt6So9lq7tjOZ1lDqQ7pcron2EgKmWaZ8s2biJgh_KWCjm5eMhc5nM4sLCGHiXQ0cmBKZH4BR26Ot-CRpao-ZWAUos5hhXA5whO6wGQeSB_SURwAFiMYy8PY3oG9Q6vVVmPFFcF7seTuL-2wZw5k1myZ8eqEw-2i9URFWcWxWd3t6uCS-TKA9ogNpfdMXGzzPLP6_XbYdwZtT270bz7ybPccVy--6dvZCj6E9RtjMDpW0Ym7J1_aZQozEiYHrsXJUJxGryVZRtcXlsKY5vs3axdDbtzxL0Di5PDlPVUxFHFUJzQ4if_FEktIcxfOMbTomfKr1cx5wRE9u56mV3TyXQxwIrQdRRBKXJpLPtmuitAG-M-gOex5XKDldg9MBIAA9JwPFU4Vo2Su-luPNipoYtxNFt1Eak5wDk_IrmlH5lsFb1Gq1Yjd1LcqX0tct42LCPx-IFRF--Yb5ACKLhbLyS5bOiRtA3sfP4LJK4H57OLwB2wsOz0eUiV7ClS0p3yGjZJ6gq2u1IUKY-qO9usP0cNUUVW1wuP_LIyM3_59cso1QSPQxS1ilL_dafralcrOQQDZHg6qoNeZTFBvoIEa0diXDL28r7KpnMI0eoAtVLVha7jPOM_fKFezj29UsR8nEFSMdgEa2fqdIov3IP26NuHIB3sqpaWuarRVgNkemn3LN_sEv276Id5AlnTeX_zG-7OoubRm4TPujkIHqymNzh9VhWCTSXBfN6PJK88k00oAV4WprxW39ote61RfqRln12oijBAhtamCL7-I8RcUAe21bqHf2shwSFzgKI7UzduI5LVMJ45jRuVFo1zf6_O20Y5Q7KBdlY6dTwrRLmVtOw51N9dobM4hyU_ReYyjvr4XdoZRM92k-XqEtmlmZSI-s8_jA9TgUnG9jIHG-W7BRQ446VEUYk81u7FRrW_Dk3ZVsthjH1Saw8X6nkiP-CXQVQSqQAThmiiVdNRMNsxyZBDFn6upM0ixP8IUIMherxX4xRS7IdaTdel4Mdk78TKZkrw03yVmYR2EhiV6_9o1mSMHUCDG5esor8ZFKpaU89-C9TBf2T3ASEmV7jTOQ6UHkWynLyRhyuIYjsGFv0UVgMwuu1OwCY37iAgU4u_3BSAVJXzDL4r_QQmIO9yO_HSh8cIFDkF9VbZ9nnAj2a90HrJzxTRBpVho.vO3WNNPPLTa54zHQMYlMWQ',
          institutionName: 'Commonwealth Bank of Australia',
        },
      }),
    );
  }),
  rest.post(entityMandateEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        success: true,
      }),
    );
  }),
  rest.get(entityDetailEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        success: true,
        data: {
          id: 1,
          jurisdictionId: 13,
          char3Code: 'AUS',
          char2Code: 'AU',
          companyType: 1,
          code: 'Cape',
          legalName: 'Cape Pty Ltd',
          tradingName: 'Cape',
          configuration: 1,
          behaviours: 0,
          logo: 'https://uploads-ssl.webflow.com/5f034cbbbcb09a728f0b94d7/5fb5b877ab30c2bea6dd4843_Cape_Logomark%20COLOUR%20solo.svg',
          primaryColour: '#fd6a68',
          externalReference: '615ab227-cd43-42f0-80f4-07767f807f5f',
          registrationNumber: '83654770199',
          state: 0,
          teams: null,
          cards: null,
          persons: null,
          users: null,
          addresses: {
            total: 1,
            items: [
              {
                id: 2,
                entityId: 1,
                code: 'Cape',
                addressId: 7,
                addressX:
                  'WOTSO Manly 19-21 S Steyne Rd   Manly NSW Australia 2095',
                addressType: 4,
              },
            ],
          },
          ledgers: null,
          allocations: null,
          phone: '+61 (0)282 316 685',
          website: 'www.getcape.io',
          email: 'support@getcape.io',
          vat: null,
          isListed: false,
          isRegulated: false,
          industry: 'Lending',
          documentId: null,
          goCardlessReference: 'CU001EZZTAFR46',
          frankieReference: null,
          frankies: null,
          ratingId: 1,
          codatReference: null,
          startDate: '2021-10-14T00:00:00',
          dueDate: '2022-04-14T00:00:00',
          anzsicId: null,
          notes: null,
          blobs: null,
          marqetaReference: null,
          established: null,
          duns: null,
          usage: null,
        },
      }),
    );
  }),

  rest.get(entityUsageEndpoint, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        success: true,
        data: {
          maximum: 100,
        },
      }),
    );
  }),
];
